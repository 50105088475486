<template>
  <div class="cmp-cross-selling utlz-reset utlz-row blocks">
    <div class="utlz-col-12">
      <h2 v-translation="{ type: 'title', code: 'title_product_relations_cross' }"></h2>
    </div>
    <div class="utlz-col-12">
      <transition-group name="fade" tag="div" class="list-items-wrapper utlz-row">
        <utlz-product-list-item
          @navigateToProductPage="navigateToProductPage($event)"
          v-for="product in products"
          :key="product.id"
          :product="product"
          :blockSize="3"
          class="product-list-item">
        </utlz-product-list-item>
      </transition-group>
    </div>
  </div>
</template>

<script>
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
export default {
  components: {
    'utlz-product-list-item': ProductListItem
  },
  props: {
    products: { type: Array, required: true }
  }
}
</script>

<style>

</style>
