<template>
  <div v-if="showStock" class="cmp-product-stock utlz-reset">
      <div class="stock-wrapper">
        <template v-if="stock !== null">

          <!-- BESPOKE PI-1221: Only show stock once it has been manually been retrieved by clicking on the hyperlink -->          
          <template v-if="stockType === 1">
            
            <template v-if="(getStockManually && stock.stockTotal !== null) || !getStockManually">
              <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
              <span class="stock-count">{{ stockTotal }}</span>
            </template>

            <template v-else>
              <a class="text-link pink-link" @click="getProductStockManually(product)" v-translation="{ type: 'label', code: 'label_fetch_stock' }"></a>
              <span v-if="loadingStock" class="loader"></span>
            </template>
          </template>
          <!-- END BESPOKE PI-1221 -->

          <template v-else-if="stockType === 2">
          <div class="stock-indicators default">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <img :src="'/' + stockIndicator.image" />
          </div>
        </template>
        <template v-else-if="stockType === 3">
          <div class="stock-indicators default">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <span>{{ stockIndicator.description }}</span>
          </div>
        </template>
          <template v-else-if="stockType === 4">
            <div class="stock-indicators">
              <img :src="'/' + stockIndicator.image" />
              <span>{{ stockIndicator.description }}</span>
            </div>
          </template>
        </template>
        <div v-else-if="showStock" class="stock-status-wrapper text-placeholder pulse">
          <span class="loader"></span>
          <span v-translation="{ type: 'label', code: 'label_retrieving_stock' }"></span>
        </div>     
      </div>

    <!-- BESPOKE PI: Added stock notification -->
    <template>
      <utlz-product-stock-signal v-if="showStock && showStockNotifications" :productCode="productCode"></utlz-product-stock-signal>    
      </template>
    </div>
    <!-- END BESPOKE PI -->   
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Product } from 'src/models/product.model';
// BESPOKE PI
import ProductStockSignal from './ProductStockSignal.vue';
// END BESPOKE PI

export default {
  components: {
    'utlz-product-stock-signal': ProductStockSignal
  },
  props: {
    product: { type: Product, required: false },
    stock: { type: Object, required: false },
    // BESPOKE PI
    // Added prop to check if product stock should be retrieved by manually clicking the get stock link
    getStockManually: { type: Boolean, required: false, default: false }
    // END BESPOKE PI
  },
  data () {
    return {
      loadingStock: false
    };
  },
  computed: {
    ...mapGetters(['showStock', 'stockIndicators', 'stockType']),
    stockTotal () {
      return this.stock.stockTotal < 0 ? 0 : this.stock.stockTotal;
    },
    stockIndicator () {
      return this.stockIndicators.find(indicator => this.stock.stockTotal <= indicator.quantity);
    },
    productCode () {
      return this.product ? this.product.id : '';
    },
    showStockNotifications () {
      if (this.stock) {
        return this.stock.stockTotal === 0;
      } else {
        return false;
      }
    }
  },
  // BESPOKE PI-1221
  // Added getProductStock action
  methods: {
    ...mapActions('elastic', ['getProductStock']),
    getProductStockManually (product) {
      this.loadingStock = true;
      this.getProductStock([product]);
    }
  }
  // END BESPOKE PI-1221
}
</script>

<style>
</style>
