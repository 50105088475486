<template>
  <div class="cmp-shopping-cart-totals" v-if="showPrices && Object.keys(shoppingCartTotals).length">
    <ul>      
      <!-- BESPOKE PI: Added donation and credit -->
      <li class="donation-total utlz-col-12 flex-row-nowrap justify-content-between totals-line">
        <span v-translation="{ type: 'label', code: 'label_donation_amount' }"></span>
        <span>{{ donationAmount }}</span>        
      </li>      
      <li class="credit-total utlz-col-12 flex-row-nowrap justify-content-between totals-line">
        <span v-translation="{ type: 'label', code: 'label_credit_amount' }"></span>
        <span>{{ creditAmount }}</span>        
      </li>
      <!-- END BESPOKE PI -->

      <!-- BESPOKE PI: Added v-for index and binded it to a numerical class so we can change the order through css -->
      <li v-for="(val, index) in format" :key="val" :class="'line-total-' + index" class="utlz-col-12 flex-row-nowrap justify-content-between totals-line">
        <!-- END BESPOKE PI -->
        <span v-translation="{ type: 'label', code: val }"></span>
        <span>{{ shoppingCartTotals[val].price }}</span>
      </li>      
    </ul>

    <!-- BESPOKE PI-1078 -->
    <template v-if="freeShipping">
      <div class="cmp-minimum-order-quantity free-shipping">
        <div class="utlz-col-md-12 min-order-quantity-message alert alert-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <h2 class="header-small">
            {{ freeShippingCostsMessage }}
          </h2>
        </div>
      </div>
    </template>
    <!-- END BESPOKE PI-1078 -->

    <template v-if="useMinimumOrderQuantity && !minimumOrderQuantityValid">
      <div class="cmp-minimum-order-quantity">
        <div class="utlz-col-md-12 min-order-quantity-message alert alert-warning">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <h2 class="header-small">
            {{ warning }}
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';
export default {
  props: {
    format: { type: Array, required: true }
  },
  data () {
    return {
      warning: '',
      // BESPOKE PI-1078
      freeShippingCostsMessage: '',
      // END BESPOKE PI-1078
    };
  },
  computed: {
    // BESPOKE PI-1078
    // Added bespokeGlobalWs
    ...mapGetters(['showPrices', 'useMinimumOrderQuantity', 'bespokeGlobalWs']),
    // END BESPOKE PI-1078
    ...mapGetters('shoppingCart', ['shoppingCartTotals', 'minimumOrderQuantityPrice', 'minimumOrderQuantityRawPrice', 'minimumOrderQuantityValid', 'orderLinesTotalRawPrice', 'getDonationAmount', 'getCreditAmount']),
    donationAmount() {
      return formatPrice(this.getDonationAmount);
    },
    creditAmount() {
      return formatPrice(0 - this.getCreditAmount);
    },
    // BESPOKE PI-1078
    freeShipping () {
      return this.shoppingCartTotals.OrderLinesTotalNet.rawPrice - this.bespokeGlobalWs.minShippingCosts < 0;
    }
    // END BESPOKE PI-1078
  },
  methods: {
    setWarning () {
      const warning = window.vue.translations.label.label_minimum_order_quantity;
      const remainingPrice = formatPrice(this.minimumOrderQuantityRawPrice - this.orderLinesTotalRawPrice);
      this.warning = warning.replace('[1]', this.minimumOrderQuantityPrice).replace('[2]', remainingPrice);
      // BESPOKE PI-1078
      // Display remaining price for free shipping costs
      const freeShippingMessage = window.vue.translations.label.label_min_free_shipping;
      const remainingFreeShipping = formatPrice(this.bespokeGlobalWs.minShippingCosts - this.shoppingCartTotals.OrderLinesTotalNet.rawPrice);
      this.freeShippingCostsMessage = freeShippingMessage.replace('[1]', remainingFreeShipping);
      // END BESPOKE PI-1078
    }
  },
  watch: {
    orderLinesTotalRawPrice () {
      this.setWarning();
    }
  },
  created () {
    this.setWarning();
  }
}
</script>

<style>

</style>
