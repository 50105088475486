<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <!-- BESPOKE PI: Changed image url to cloudinary image property -->
            <img :src="promptPayload.product.imageUrlMedium">
            <!-- END BESPOKE PI -->
          </div>
          <div class="utlz-col-9">
            <!-- BESPOKE PI-1087: Added product code -->
            <span class="prod-code">
              {{ promptPayload.product.id }}
            </span>
            <!-- END BESPOKE PI-1087 -->
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <!-- BESPOKE moved the button from below the overview -->
    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">      
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
      </div>
    </template>
    <!-- END BESPOKE -->

    
    <utlz-static-modal v-if="showValidatingStockModal">
      <template v-slot:static-modal-content>
        <div class="validate-stock-modal utlz-row">
          <div class="utlz-col-12 flex-row-nowrap align-center just-center">
            <template v-if="loadingOrderLines && initialCheck">              
              <transition name="fadein">
                <div class="loader-container">
                <div class="loader-wrapper">                
                  <span class="loader"></span>
                </div>
                <h2>
                  <span v-translation="{ type: 'label', code: 'label_loading_orderlines' }"></span>
                </h2>
                </div>
              </transition>
            </template>
            <template v-else-if="validatingStock">
              <transition name="fadein">
                <div class="loader-container">
                <div class="loader-wrapper">                
                  <span class="loader"></span>
                  <span class="percentage">{{ stockCheckProgress }}%</span>
                </div>
                <h2>
                  <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
                </h2>
                </div>
              </transition>
            </template>  
          </div>
        </div>
      </template>
    </utlz-static-modal>
    

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <!-- BESPOKE PI: Added h2 -->
    <div class="utlz-col-12">
      <h2 v-translation="{ code: 'title_shopping_cart', type: 'title' }"></h2>
    </div>
    <!-- END BESPOKE PI -->

    <transition name="fade">
      <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>
          <div class="prod-price orderline-cell">
            <span v-if="showPrices" v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>

          <div class="prod-stock orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div>

          <div class="order-product orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>
          <div class="prod-total orderline-cell">
            <span v-if="showPrices" v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>
          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition>

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines">
        <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row">

          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
            <a :href="orderLine.product.targetUrl" :class="{ 'disabled': orderLine.customFields.coupon }">
              <img :src="orderLine.product.imageUrl" />
            </a>
          </div>

          <div class="prod-desc orderline-cell">
            <a :href="orderLine.product.targetUrl" :class="{ 'disabled': orderLine.customFields.coupon }">
              <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
              <span class="prod-code">{{ orderLine.product.id }}</span>
            </a>

            <template v-if="useOrderLineComments && screenWidth >= 880">
              <utlz-order-line-comment :orderLine="orderLine"></utlz-order-line-comment>
            </template>
          </div>

          <template v-if="showPrices">
            <template v-if="!orderLine.customFields.coupon">
              <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
                <utlz-product-price :prices="orderLine.totals"></utlz-product-price>
              </div>
              <div v-else class="stock-status-wrapper text-placeholder pulse">
                <span class="loader"></span>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="prod-price orderline-cell spacer"></div>
          </template>
          <div class="prod-stock orderline-cell">
            <utlz-product-stock v-if="!orderLine.customFields.coupon"
                                :stock="orderLine.product.stock"
                                :stockTotal="orderLine.product.stock"></utlz-product-stock>
          </div>

          <!-- BESPOKE PI: Added :parentComponentName -->
          <div class="order-product orderline-cell">
            <span v-if="loading" class="loader"></span>
            <utlz-order-product v-if="orderLine.product.stock !== null && orderLine.product.prices.length"
                                :product="orderLine.product"
                                :initialQuantity="orderLine.quantity"
                                :initialUnitCode="orderLine.unitCode"
                                :useOrderMode="false"
                                :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
                                :allowZeroQuantity="false"
                                :parentComponentName="'shoppingcart'"
                                @quantityChanged="quantityChanged($event, orderLine)"
                                @unitsChanged="unitsChanged($event, orderLine)"></utlz-order-product>
          </div>
          <!-- END BESPOKE PI -->

          <div class="prod-total orderline-cell">
            <span>{{ orderLine.totals.totalPrice }}</span>
          </div>

          <div class="delete-prod orderline-cell">
            <span v-if="!orderLine.customFields.coupon" class="close-button small grey" @click="promptDelete(orderLine)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          </div>

          <template v-if="useOrderLineComments && screenWidth < 880">
            <div class="mobile-order-comment">
              <utlz-order-line-comment :orderLine="orderLine"></utlz-order-line-comment>
            </div>
          </template>

        </div>
      </template>

    </transition-group>    

    <div class="utlz-col-12 donation-banners-container">
      <utlz-shopping-cart-donation-banner></utlz-shopping-cart-donation-banner>
    </div>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

    </div>

    <div class="utlz-col-md-6">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import _ from 'lodash';
// BESPOKE
import ShoppingCartDonationBanner from './../../donations/ShoppingCartDonationBanner.vue';
// END BESPOKE

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    // BESPOKE
    'utlz-shopping-cart-donation-banner': ShoppingCartDonationBanner
    // END BESPOKE
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      localDonationAmount: 0,
      // BESPOKE
      renderedContinueButton: false,
      initialCheck: window.vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartInitial
      // END BESPOKE
    }
  },
  // BESPOKE PI
  // Added watcher for donation amount
  watch: {
    donationAmount () {
      this.localDonationAmount = this.donationAmount;
    }
  },
  // END BESPOKE PI
  computed: {
    ...mapState('shoppingCart', ['donationAmount']),
    // BESPOKE PI
    // Added bespokeGlobalWs
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'bespokeGlobalWs', 'showPrices']),
    // Added stockCheckProgress    
    ...mapGetters('shoppingCart', [
      'orderLines', 
      'invalidOrderLines', 
      'loadingOrderLines', 
      'orderLinesTotal', 
      'minimumOrderQuantity', 
      'shoppingCartEmpty', 
      'shoppingCartTotalsFormat', 
      'validatingStock', 
      'getDonationAmount', 
      'stockCheckProgress'
      ]),
    // END BESPOKE PI
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    },
    showValidatingStockModal () {
      let initialStockCheck = vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartInitial;
      let checkoutStockCheck = vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartCheckout;
      if ((this.loadingOrderLines && initialStockCheck) || (this.validatingStock && initialStockCheck)) {
        return true;
      } else if (this.validatingStock && checkoutStockCheck) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // BESPOKE PI
    // Added updateDonation
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart', 'updateDonation']),
    // END BESPOKE PI

    // BESPOKE PI
    // Added trueQuantity in the payload
    quantityChanged (quantities, orderLine) {
      let computedQuantity = quantities.computedQuantity;
      let trueQuantity = quantities.trueQuantity;
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree,
        TrueQuantity: trueQuantity
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    // END BESPOKE PI
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE PI
    updateDonationLocal() {
      var absDonation = Math.abs(this.localDonationAmount);
      this.localDonationAmount = absDonation;
      if (absDonation !== this.donationAmount) {
        this.updateDonation(absDonation == '' ? 0 : absDonation);
      }
    }
    // END BESPOKE PI
  },
  created () {
    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);
    
  },
  // BESPOKE PI
  // Added some jquery ot clone the continue shopping button to the top of the page
  updated() {   
    if (!this.renderedContinueButton) {      
      window.$('.clear-cart-col').prepend(window.$('.uc_multisafepay .black-btn').clone());
      this.renderedContinueButton = true;
    }
  }
  // END BESPOKE PI-1077
}
</script>

<style>

</style>
