<template>
  <!-- BESPOKE PI: Added classes utlz-col-lg-4 and utlz-col-xl -->
  <div class="cmp-product-list-item utlz-col-sm-4 utlz-col-lg-4" :class="'utlz-col-xl-' + blockSize">
  <!-- END BESPOKE PI -->

    <!-- BESPOKE PI-1221: Added modal to alter the quantity or show stock notification is orderProduct wih a stock check it fired -->
    <transition name="fadein">
      <utlz-static-modal v-if="showOrderProductModal">
        <template v-slot:static-modal-content>
          <div class="adjust-quantities-modal">
            <div class="modal-content">
              <a @click="showOrderProductModal = false" class="close-button">
                <i class="fa fa-times-circle"></i>
              </a>
              <div class="modal-header">
                <h1 v-translation="{ type: 'title', code: 'title_adjust_quantities' }"></h1>
                <h2 v-translation="{ type: 'text', code: 'text_adjust_quantities' }"></h2>
              </div>
              <div class="modal-body">
                <div class="utlz-container">
                  <div class="utlz-row">
                    <div class="utlz-col-md-3 prod-img">
                      <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrlMedium : '/documents/productimages/not-available-large.jpg'" />
                    </div>
                    <div class="utlz-col-md-9 prod-info">
                      <h2>{{ product.shortDescription }}</h2>
                      <utlz-product-stock
                        :product="product"
                        :stock="product.stock"
                        :stockTotal="product.stock"
                        :getStockManually="true"
                      ></utlz-product-stock>

                      <utlz-order-product
                          :initialQuantity="10"
                          :product="computedProduct"
                          :useTextOrderButton="true"
                          @orderButtonClick="showOrderProductModal = false"
                          >
                      </utlz-order-product> 
                    </div>
                  </div>
                </div>         
              </div>     
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>
    
    <transition name="fadein">
      <utlz-product-stock-signal 
        v-if="showStockNotificationModal" 
        :productCode="product.id"
        :useAsPrompt="true"
        @toggleNotificationModal="showStockNotificationModal = false"  
        :product="product"
      >
      </utlz-product-stock-signal>   
    </transition> 
    <!-- END BESPOKE PI-1221 -->

    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      <template v-if="product.prices != 'undefined'">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      </template>
      <div class="utlz-col-3 utlz-col-sm-12 img-container">
        
        <!-- BESPOKE PI: Disabled product favorites -->
        <!-- <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control> -->
        <!-- END BESPOKE PI -->

          <template
            v-if="screenWidth >= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

        <figure class="img-square prod-img">
          <!-- BESPOKE PI: Added @click.stop.prevent="navigateToProductPage()" so we can push data to google dataLayer -->
          <a :href="product.targetUrl" class="clickthrough" :class="imageRatioClass" @click.stop.prevent="navigateToProductPage()">

            <!-- BESPOKE PI: Added :srcset to image so we can use cloudinary images as a srcset -->
            <img v-if="screenWidth < 576" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrlSmall : '/documents/productimages/not-available-large.jpg'" />
            <img v-if="screenWidth >= 576" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrlMedium : '/documents/productimages/not-available-large.jpg'" />
            <!-- <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" sizes="(max-width: 991px) 100px, (min-width: 992px), 190px" :srcset="product.imageSrcSet" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" /> -->
            <!-- END BESPOKE PI -->
            
          </a>
          <!-- END BESPOKE PI -->
        </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">

        <!-- BESPOKE PI: Added @click.stop.prevent="navigateToProductPage()" so we can push data to google dataLayer -->
        <a :href="product.targetUrl" class="clickthrough" @click.stop.prevent="navigateToProductPage()">
          <!-- END BESPOKE PI -->

          <template v-if="screenWidth <= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <div class="product-code">
            <span class="value">{{ product.id }}</span>
          </div>
          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
          <!-- BESPOKE PI-577: Added properties object -->
          <ul class="properties-list keyval-pair">
            <li v-for="(value, key, index) in product.properties" v-if="index < 2" :key="key" class=" flex-row-nowrap justify-content-between">
              <span class="key">{{ key }}:</span>
              <span class="value">{{ value }}</span>
            </li>
          </ul>
          <!-- END BESPOKE PI-577 -->
        </a>

        <div class="ph-product-price" v-if="product.prices.length">
          <utlz-product-price
            :prices="product.prices[0]"
            :parentClassRef="'ref-product-list-item'"
          ></utlz-product-price>
        </div>

        <utlz-product-variant
          v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant>

        <!-- BESPOKE PI: Added !showStockNotifications and initialQuantity -->
        <div class="ph-order-product">
          <transition name="fade">
            <!-- BESPOKE PI: 
              - Added product.prices.length to make sure prices have been retrieved 
                which is needed to calculate order volume discounts dropdown
              - Added @handleOrderProductModal
              - Added @handleStockNotificationModal
               -->
            <utlz-order-product v-if="showOrderProductControl && product.prices.length"
                                :product="computedProduct"
                                :parentClassRef="'ref-product-list-item'"
                                :getStockManually="true"
                                @orderButtonClick="handleOrderButtonClick"
                                @handleOrderProductModal="handleOrderProductModal($event)"
                                @handleStockNotificationModal="handleStockNotificationModal()"
                                >
            </utlz-order-product>
            <!-- END BESPOKE PI -->
          </transition>

          <!-- BESPOKE PI: Added stock notification and disabled default out of stock message -->
          <!-- <transition name="fade">
            <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
              <i class="fa fa-warning"></i>
              <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
            </p>
          </transition> -->
          <!-- END BESPOKE PI -->
        </div>

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->
        <!-- BESPOKE PI-1221: Added :getStockManually="true" since we want to manually retrieve stock in utlz-product-stock -->
        <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :product="product"
          :stock="product.stock"
          :stockTotal="product.stock"
          :getStockManually="true"
        ></utlz-product-stock>
        <!-- END BESPOKE PI-1221 -->

      </div>

    </div>
  </div>
</template>

<script>
// BESPOKE PI
// Imported mapGetters
import { mapGetters, mapActions, mapState } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
// BESPOKE PI
import ProductStockSignal from './../../product-stock/ProductStockSignal.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
// END BESPOKE PI
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    // BESPOKE PI
    'utlz-product-stock-signal': ProductStockSignal,
    'utlz-static-modal': StaticModal
    // END BESPOKE PI
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      isSalesAction: this.product.prices[0],
      activeProductVariantId: null,
      imageRatioClass: '',
      // BESPOKE PI
      showOrderProductModal: false,
      showStockNotificationModal: false
      // END BESPOKE PAL
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    ...mapState(['products']),
    ...mapGetters(['screenWidth','showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList']),
    showOrderProductControl() {
      if (this.showOrderProduct) {
        // BESPOKE PI-1221
        // Always show order product, since we will check stock once product has been added to the cart
        // or manually retrieved using the hyperlink in the product stock control
        return true;
        // END BESPOKE PI-1221
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit || !this.stockProduct) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    },
    productImageBanners () {
      return this.product.productImageBanners.filter(banner => banner.show === true);
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    },    
    handleOrderButtonClick() {
      this.$emit('orderButtonClick')
    },
    ...mapActions('elastic', ['pushProductClickToDataLayer']),
    navigateToProductPage () {
      if (this.products){
        return this.pushProductClickToDataLayer(this.product.id);
      } else {
        document.location = this.product.targetUrl;
      }
    },
    // BESPOKE PI
    handleOrderProductModal (stockStatus) {
      this.showOrderProductModal = true;
    },
    handleStockNotificationModal () {
      this.showStockNotificationModal = true;
    }
    // END BESPOKE PI
  }
};
</script>

<style>
</style>
