<template>
  <div class="cmp-order-volume-discount">
    <div class="custom-select-box">
      <div class="current" @click="selectExpanded = !selectExpanded" v-outside-click="{ handler: 'closeDropdown', exclude: ['dropdown']}" ref="dropdown">
        <span v-translation="{ type: 'label', code: 'label_volume_discount'}" class="prefix"></span>
        <span class="volume-amount">{{ volumeDiscount[selectedIndex].quantity }}</span>
        <span class="multiply">x</span>
        <span class="volume-price">{{ volumeDiscount[selectedIndex].price }}</span>
        <!-- BESPOKE PI-884: Show basePrice if lower than customer price -->
        <span v-if="volumeDiscount[selectedIndex].price < volumeDiscount[selectedIndex].basePrice" class="default-price">{{ volumeDiscount[selectedIndex].basePrice }}</span>
        <!-- END BESPOKE PI-884 -->
        <i class="fa fa-angle-down"></i>
      </div>
      <ul v-if="selectExpanded" class="options">
        <li v-for="(volume, index) in volumeDiscount" :key="index" @click="updateSelected(index)" class="option" v-bind:class="{ selected: selectedIndex === index }">
          <span v-translation="{ type: 'label', code: 'label_volume_discount'}" class="prefix"></span>
          <span class="volume-amount">{{ volume.quantity }}</span>
          <span class="multiply">x</span>
          <span class="volume-price">{{ volume.price }}</span>
          <!-- BESPOKE PI-884: Show basePrice if lower than customer price -->
          <span v-if="volume.unformattedPrice < volume.unformattedBasePrice" class="default-price">{{ volume.basePrice }}</span>
          <!-- END BESPOKE PI-884 -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialVolume: { type: Object, required: false },
    volumeDiscount: { type: Array, required: true },
    initialQuantity: { type: Number, required: false, default: 1}
  },
  data () {
    return {
      selectExpanded: false,
      selectedIndex: 0
    }
  },
  created() {
    if (this.volumeDiscount[0].quantity < this.initialQuantity) {
      this.volumeDiscount[0].quantity = this.initialQuantity;
    }
  },
  methods: {
    expandSelect () {
      this.selectExpanded = true;
    },
    updateSelected (index) {
      this.selectedIndex = index;
      this.$emit('volumeDiscountChanged', this.volumeDiscount[index].quantity);
    },
    closeDropdown () {
      this.selectExpanded = false;
    }
  }
}
</script>

<style>

</style>
