import { formatPrice, calculatePrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    // BESPOKE PI: Retrieve modified description
    this.shortDescription = this.getShortDescription(product.shortDescription);
    // END BESPOKE PI
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.shortDescription;
    this.imageTitleText = product.shortDescription;
    this.productImageBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
    // BESPOKE PI
    this.imageUrlSmall = this.getCloudinaryUrl('xs', product.imageUrl);
    this.imageUrlMedium = this.getCloudinaryUrl('sm', product.imageUrl);
    this.imageUrlXLarge = this.getCloudinaryUrl('xl', product.imageUrl);
    this.cloudinaryImages = [];
    // END BESPOKE PI
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setProductImageBanners (banners) {
    banners.forEach(banner => {
      this.productImageBanners[banner.Location].values.push(banner);
      this.productImageBanners[banner.Location].show = true;
    });
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }  

  setPrices(prices) {

    const getters = window.vuexStore.$store.getters;

    if (prices !== undefined) {
      let formattedPrices = [];
      prices.forEach(priceObj => {
        let priceIncl = null;
        let basePriceIncl = null;

        if (getters.priceType === 3) {
          let vatPerc = 1 + this.customDecimals.VAT_PERCENTAGE / 100;
          priceIncl = formatPrice(priceObj.price * vatPerc);
          basePriceIncl = formatPrice(priceObj.price * vatPerc);
        }

        formattedPrices.push({
          rawPrice: calculatePrice(priceObj.price),
          rawBasePrice: calculatePrice(priceObj.basePrice),
          price: formatPrice(priceObj.price),
          basePrice: formatPrice(priceObj.basePrice),
          priceIncl: priceIncl,
          basePriceIncl: basePriceIncl,
          quantity: priceObj.quantity === 0 ? 1 : priceObj.quantity,
          isSalesAction: priceObj.isSalesAction
        });
      });
    }
  }
  // BESPOKE PI: Strips --string from shortdescription
  getShortDescription (desc) {
    let descArr = desc.split('--');
    return descArr[0];
  }
  // END BESPOKE PI

  // BESPOKE PI: Setup volume discount and saleunits
  // If product has a saleunit > 1 it is leading the first dropdown value in the volume discount dropdown
  // If product has a min order quantity it is the first dropown value in the volume discount dropdown
  // If product has no sale unit or min order quantity the default quantity for the first dropdown is 1
  setPricesBespoke (prices, saleUnit, minOrderQuantity) {
    prices.forEach((priceObj, i) => {

      priceObj.rawPrice = priceObj.price;
      priceObj.rawBasePrice = priceObj.basePrice;

      // BESPOKE PI-1320
      // Added unformatted prices needed for comparison in other components 
      priceObj.unformattedPrice = priceObj.price;
      priceObj.unformattedBasePrice = priceObj.basePrice;
      // END BESPOKE PI-1320

      const qty = priceObj.quantity !== 0 ? priceObj.quantity : 1;
      priceObj.price = formatPrice(priceObj.price);
      priceObj.basePrice = formatPrice(priceObj.basePrice);

      if (saleUnit > 1 && i === 0) {
        priceObj.quantity = qty * saleUnit;
        priceObj.saleUnit = saleUnit;
      } else {
        if (i === 0) {
          if (minOrderQuantity !== 0 && priceObj.quantity === 0) {
            priceObj.quantity = minOrderQuantity;
          } else if (priceObj.quantity === 0) {
            priceObj.quantity = qty;
          }
        }
      }
    });
    this.prices = prices.sort(function(x, y){ return x.quantity - y.quantity});
  }
  // END BESPOKE PI

  // BESPOKE PI
  // Handles cloudinary images
  getCloudinaryUrl (size, imageUrl) {
    const getters = window.vuexStore.$store.getters;
    const dimensions = getters.bespokeGlobalWs.cloudinaryImgSizes.split(',');

    let imgWidth;
    switch(size) {
      case 'xs':
        imgWidth = dimensions[0];
        break;
      case 'sm':
        imgWidth = dimensions[1];
        break;
      case 'md':
        imgWidth = dimensions[2];
        break;
      case 'lg':
        imgWidth = dimensions[3];
        break;
      case 'xl':
        imgWidth = dimensions[4];
        break;
    }

    let url = imageUrl.replace('UTI_IMG_SIZE', imgWidth);
    let urlId = url.replace(/UTI_IMGID/g, this.id);

    return urlId.replace(/,/g, '%2c');
  }

  setCloudinaryUrls () {
    this.images.forEach((image, index) => {

      var labelTranslations = window.vue.translations.label;
      var postFix = "";

      if (index == 0) {
        postFix = labelTranslations.lbl_alt_whlsl;
      } else if (index == 1) {
        postFix = labelTranslations.lbl_alt_buy;
      } else if (index == 2) {
        postFix = labelTranslations.lbl_alt_shp;
      } else  if (index == 3) {
        postFix = labelTranslations.lbl_alt_splr;
      }

      var altText = this.shortDescription.trim() + " " + postFix;

      // BESPOKE PI-1474
      // Depending on the index of the image, determine it's altext
      // Every 1st, 2nd, 3rd, 4th and 5th image will have an alternative text
      this.cloudinaryImages.push({ 
        altText: altText,
        titleText: altText + " - Phoenix Import",
        small: this.getCloudinaryUrl('sm', image),
        large: this.getCloudinaryUrl('xl', image),
      });
    });
  }
  // END BESPOKE PI

}
