<template>
    <div class="utlz-row cart-donation-banners" v-if="!loading && render">
      <div class="utlz-col-lg-6" v-for="banner in donationBanners" :key="banner.ProdCode">
        <utlz-shopping-cart-donation-banner-item :banner="banner" :donationAmountProp="banner.donationAmount" @updateDonation="updateDonationAmount"></utlz-shopping-cart-donation-banner-item>
      </div>
    </div>
</template>

<script>

import axios from 'axios';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ShoppingCartDonationBannerItem from './ShoppingCartDonationBannerItem.vue';
import { formatPrice } from 'src/utilities/helpers';
export default {
  components: {
    'utlz-shopping-cart-donation-banner-item': ShoppingCartDonationBannerItem
  },
  data() {
    return {
      loading: true,
      donationBanners: [],
      render: true
    }
  },
  watch: {
    // Keeps track of if the cart has been emptied
    shoppingCartEmpty(val) {
      if (val) {
        this.render = false;
        this.$nextTick(() => {
          this.donationBanners.forEach(x => x.donationAmount = 0);
          this.render = true;
        });
      }
    }
  },
  computed: {
    ...mapGetters(['language', 'bespokeEndpoints']),
    ...mapGetters('shoppingCart', ['shoppingCartEmpty'])
  },
  methods: {
    ...mapActions('shoppingCart', ['updateDonation']),
    updateDonationAmount({ amount, prodCode }) {
      this.updateDonation({ prodCode: prodCode, amount: amount });
    }
  },
  created() {
    axios.post(this.bespokeEndpoints.activeDonations, { language: this.language }).then(donationBannersResponse => {
        this.donationBanners = donationBannersResponse.data.d;

          axios.post(this.bespokeEndpoints.shoppingCartDonations, { language: this.language }).then(res => {
            var donations = res.data.d.Donation;
            
            this.donationBanners.forEach(banner => {
                  banner.formattedtotalDonations = formatPrice(banner.DonatedAmount);
                  banner.formattedtotalDonationsPI = formatPrice(banner.DonatedAmount * 2);

                  var donatedProduct = donations.find(donatedProd => banner.ProdCode == donatedProd.ProdCode);

                  if (donatedProduct !== undefined) {
                    banner.donationAmount = donatedProduct.Ammount;
                  } else {
                    banner.donationAmount = 0;
                  }
            });
            
            this.loading = false;
          });
    });    
  }
}
</script>

