<template>
  <div class="banner-inner">
    <img class="bg-image" :src=" `/${banner.BackgroundImage}`"/>
      <div class="inner-wrapper" :style="'background-color: ' + banner.BannerColor + ';'"></div>
      <div class="don-text-container">
        <div class="text-top">
          <div v-html="banner.TextBoxUp" />
        </div>

        <div class="amounts-list">
          <div class="amt-row">
            <span class="lbl" v-translation="{ code: 'lbl_donations', type: 'label'}"></span>
            <span class="lbl" v-translation="{ code: 'lbl_adds_up', type: 'label'}"></span>
          </div>
          <div class="amt-row">
            <span class="val">{{ banner.formattedtotalDonations }}</span>
            <span class="val">{{ banner.formattedtotalDonationsPI }}</span>
          </div>
        </div>

        <div class="text-bottom">
          <div v-html="banner.TextBoxDown" />
        </div>
        <div class="form-row">
          <a :href="banner.MoreInfoUrl" class="white-btn-outline button" v-translation="{ code: 'button_donation_info', type: 'button' }">
          </a>
          <div class="form-group">
            <input class="don-amt" min="0" type="number" step="0.1" v-model="donationAmount" placeholder="uw donatie bedrag" @change="validateAmount">
            <a class="button theme-custom" :style="'background-color: ' + banner.ButtonColor + ';'" @click="updateDonation" v-translation="{ code: 'button_donation_donate', type: 'button' }">
            </a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    banner: { type: Object, required: true }
  },
  data() {
    return {
      donationAmount: 0
    }
  },
  methods: {
    validateAmount(event) {
      var validatedInput = this.parseInput(event.target.value);
      if (validatedInput === -1) {
        this.donationAmount = this.banner.donationAmount;
      } else {
        this.donationAmount = validatedInput;
      }
    },
    updateDonation() {
      var previousDonation = this.parseInput(this.banner.donationAmount);
      if (previousDonation != this.donationAmount || this.donationAmount == 0 && previousDonation > 0) {
        this.banner.donationAmount = this.donationAmount;
        this.$emit('updateDonation', { amount: this.donationAmount, prodCode: this.banner.ProdCode });
      }
    },
    parseInput(inputString) {
      var newValue = parseFloat(inputString);

      if (isNaN(newValue) || newValue < 0) {
        return -1;
      }

      // check if the passed value is a number
      if(typeof newValue == 'number' && !isNaN(newValue)){
        // check if it is integer
        if (Number.isInteger(newValue)) {
          return newValue;
        }
        else {
          return parseFloat(newValue).toFixed(2);
        }
      } else {
        return -1;
      }
    },
  },
  created() {
    this.donationAmount = this.parseInput(this.banner.donationAmount);
  }
}
</script>

<style>

</style>