<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
      <div class="utlz-col-md-4 column-left">
        <utlz-gallery :product="productDetails" :seoInformation="imageSeoInfo"></utlz-gallery>
      </div>
      <div class="utlz-col-md-8 column-right product-details-column">
          <div class="product-information">
            <h1>{{productDetails.shortDescription}}</h1>
            <!-- BESPOKE PI: Disabled product code -->
            <!-- <div class="product-code">
                <span>{{productDetails.id}}</span>
            </div> -->
            <!-- END BESPOKE PI -->
            <div class="ph-product-price" v-if="productDetails.prices.length">
                <utlz-product-price
                    :prices="productDetails.prices[0]"
                ></utlz-product-price>
            </div>

            <!-- BESPOKE PI-1084: Added advisory price -->
            <div class="suggested-price" v-if="productDetails.customDecimals.SUGGESTED_PRICE">
                <span v-translation="{ code: 'label_suggested_price_text', type: 'label' }"></span>
                <span class="value">{{ suggestedPrice }}</span>
            </div>
            <!-- END BESPOKE PI-1084 -->

            <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
              <div ref="productDescription" class="prod-desc-wrapper">
                <div class="product-long-description" v-html="productDetails.longDescription">
                </div>
              </div>
            </div>
            <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
              <span v-if="!expanded" v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
              <span v-if="expanded" v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
            </a>            
            
            <div class="product-specifications" v-if="productProperties.length > 0">
              <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2>
              <div class="spec-list">
                <!-- BESPOKE PI: Added product code -->
                <div class="spec-item">
                  <div class="spec-desc">
                    <span v-translation="{ code: 'label_product_code', type: 'label' }"></span>
                  </div>
                  <div class="spec-value">{{ productDetails.id }}</div>
                </div>
                <!-- END BESPOKE PI -->
                <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                  <div class="spec-desc">
                    {{prop.key}}
                  </div>
                  <div class="spec-value">
                    {{prop.value}}
                  </div>
                </div>

                <!-- BESPOKE PI-1083 -->
                <div class="spec-item" v-if="productDetails.customStrings.UITLOOP_STATUS !== undefined && productDetails.customStrings.UITLOOP_STATUS !== ''">
                  <div class="spec-desc" v-translation="{ type: 'label', code: 'lbl_expiring_article' }"></div>
                </div>
                <!-- END BESPOKE PI-1083 -->

              </div>
            </div>   

            <div class="product-documents utlz-col-12" v-if="documents.length">
              <h2 v-translation="{ type: 'label', code: 'label_tab_product_documents' }"></h2>
              <utlz-product-documents :documents="documents"></utlz-product-documents>
            </div>

            <utlz-group-dropdown :variants="productVariants" />    

            <div class="order-product">
              <utlz-order-product
                v-if="showOrderProductControl"
                :useTextOrderButton="true"
                :product="productDetails"
                :useOrderComment="useOrderLineComments"
                >
                </utlz-order-product>
                <!-- BESPOKE PI-1048: Disable out of stock message -->
                <!-- <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition> -->
                <!-- END BESPOKE PI-1048 -->
            </div>
            
            <div class="favorite">
              <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
            </div>

            <!-- BESPOKE PI: Moved product stock down, and added :product="productDetails" -->
            <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock>
            <!-- END BESPOKE PI -->

            <!-- BESPOKE PI: Added anchor to product matrix and login buttons -->
            <div class="action-btns" :class="{ 'logged-out': !userLoggedOn }">
              <div v-if="productVariants.length" class="matrix-anchor-wrapper">
                <a @click="scrollToMatrix()" id="matrix-anchor">
                    <i class="fa fa-level-down" aria-hidden="true"></i>
                    <span v-translation="{ code: 'label_to_matrix', type: 'label' }"></span>
                </a>
              </div>
              <div class="login-for-prices" v-if="!userLoggedOn">
                <a :href="'/' + language +'/webshop/login.aspx'" class="button small-pink theme-primary login-prices" v-translation="{ type: 'button', code: 'button_login_prices' }"></a>
                <a :href="'/' + language +'/webshop/paymentprocess/register.aspx'" class="pink-link" v-translation="{ type: 'button', code: 'button_register_here' }"></a>
              </div>
            </div>
            <!-- END BESPOKE PI -->

          </div>
      </div>

      <div class="utlz-col-12 margin-bottom-30"></div>

      <div class="product-videos utlz-col-12" v-if="videos.length">
        <utlz-product-videos :videos="videos"></utlz-product-videos>
      </div>

      <div v-if="productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div>

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>

  </div>
</template>

<script>
// BESPOKE PI
import { formatPrice } from 'src/utilities/helpers';
// END BESPOKE PI
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import UpSelling from 'src/components/webshop/products/related-products/UpSelling.vue';
import CrossSelling from 'src/components/webshop/products/related-products/CrossSelling.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';

export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-up-selling': UpSelling,
    'utlz-cross-selling': CrossSelling,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true }
      ]
    }
  },
  watch: {
    documents (val) {
      if (val.length) {
        this.tabs.push({ id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false });
      } else {
        this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'documents'), 1);
      }
    },
    videos (val) {
      if (val.length) {
        this.tabs.push({ id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false });
      } else {
        this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'videos'), 1);
      }
    }
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'flickityOptions', 'productVariants', 'imageSeoInfo', 'relatedProducts', 'videos', 'documents']),
    // BESPOKE PI
    // Added userLoggedOn, language
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'userLoggedOn', 'relatedProductBlockSize', 'language']),
    // END BESPOKE PI
    ...mapGetters('productDetails', ['documents']),
    useCollapse () {
      // BESPOKE PI
      // Changed height to 72
      return this.productDescriptionHeight > 72;
      // END BESPOKE PI
    },    
    // BESPOKE PI-1084 Added suggested price
    suggestedPrice () {
      return formatPrice(this.productDetails.customDecimals.SUGGESTED_PRICE);
    },
    // END BESPOKE PI-1084
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      // BESPOKE PI-1333
      // Add EAN code to the top of the list, so it is displayed 
      // underneath the product code
      var props= [];

      Object.keys(this.productDetails.properties).forEach(x => {

        // BESPOKE PI-1482
        // If value is true or false, cange to yes no
        var value = this.productDetails.properties[x];

        if (value.toLowerCase() == "true")
          value = window.vue.translations.label.lbl_yes;
        else if (value.toLowerCase() == "false") {
          value = window.vue.translations.label.lbl_no;
        }

        let index = props.findIndex(obj => obj.key.toLowerCase().trim() === x.toLowerCase().trim());

        var prop = { key: x, value: value };

        if (index > -1) {
          props[index].value += " | " + value;
        } else {
          if (x === 'EAN') {           
          props.unshift(prop);
          } else {
            props.push(prop);
          }
        }
      });
      return props;
      // END BESPOKE PI-1333
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails']),
    // BESPOKE PI
    scrollToMatrix () {
      document.getElementById('product-matrix').scrollIntoView({ behavior: 'smooth' });
    }
    // END BESPOKE PI
  }
}
</script>

<style>

</style>
