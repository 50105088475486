import axios from 'axios';
import { Product } from 'src/models/product.model';
import { OrderLine } from 'src/models/order-line.model';
import { formatPrice } from 'src/utilities/helpers';

const state = {
  products: [],
  orderLines: [],
  productCodes: [],
  totalProductsCount: 0,
  shoppingCartInitizalized: false,
  loadingOrderLines: false,
  validatingStock: false,
  shoppingCartTotals: {
    CouponsTax: { price: '€ 0,00', rawPrice: 0 },
    CouponsNet: { price: '€ 0,00', rawPrice: 0 },
    CouponsTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTax: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsNet: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTotal: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsTax: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsNet: { price: '€ 0,00', rawPrice: 0 },
    ShippingCosts: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalTax: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalNet: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderTotal: { price: '€ 0,00', rawPrice: 0 }
  },
  shoppingCartValid: false,
  shoppingCartEmpty: false,
  minimumOrderQuantityPrice: '€ 0,00',
  minimumOrderQuantityRawPrice: 0,
  orderLinesTotalPrice: '€ 0, 00',
  orderLinesTotalRawPrice: 0,

  invalidOrderLines: [],
  totalOrderLines: 0,
  stockValid: false,
  couponCode: '',
  hasCoupon: false,
  shoppingCartTotalsFormat: [],
  shoppingCartControlTotalsFormat: [],
  //BESPOKE
  donationAmount: 0,
  creditAmount: 0,
  stockCheckProgress: 0
  // END BESPOKE
};

const getters = {
  productCodes (state) {
    return state.productCodes;
  },
  orderLines (state) {
    return state.orderLines;
  },
  totalProductsCount (state) {
    return state.totalProductsCount;
  },
  loadingOrderLines (state) {
    return state.loadingOrderLines;
  },
  validatingStock (state) {
    return state.validatingStock;
  },
  shoppingCartInitizalized (state) {
    return state.shoppingCartInitizalized;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;
    return `${endpoint}/${client}?language=${language}`;
  },
  shoppingCartTotals (state) {
    return state.shoppingCartTotals;
  },
  shoppingCartTotalsFormat (state) {
    return state.shoppingCartTotalsFormat;
  },
  shoppingCartControlTotalsFormat (state) {
    return state.shoppingCartControlTotalsFormat;
  },
  stockValid (state, getters, rootState, rootGetters) {
    if (!rootGetters.stockLimit) {
      return true;
    } else {
      return state.stockValid;
    }
  },
  invalidOrderLines (state) {
    return state.invalidOrderLines;
  },
  shoppingCartEmpty (state) {
    return state.shoppingCartEmpty;
  },
  orderLinesTotal (state) {
    return state.shoppingCartTotals.OrderLinesTotal;
  },
  minimumOrderQuantity (state) {
    return state.minimumOrderQuantity;
  },
  minimumOrderQuantityPrice (state) {
    return state.minimumOrderQuantityPrice;
  },
  minimumOrderQuantityRawPrice (state) {
    return state.minimumOrderQuantityRawPrice;
  },
  orderLinesTotalPrice (state) {
    return state.orderLinesTotalPrice;
  },
  orderLinesTotalRawPrice (state) {
    return state.orderLinesTotalRawPrice;
  },
  minimumOrderQuantityValid (state, getters, rootState, rootGetters) {
    if (rootGetters.useMinimumOrderQuantity) {
      return getters.orderLinesTotalRawPrice - getters.minimumOrderQuantityRawPrice + getters.getCreditAmount >= 0;
    } else {
      return true;
    }
  },
  couponCode (state) {
    return state.couponCode;
  },
  hasCoupon (state) {
    return state.hasCoupon;
  },
  showCheckoutButton (state, getters) {
    return getters.stockValid && getters.minimumOrderQuantityValid;
  },
  getDonationAmount(state) {
    return state.donationAmount;
  },
  getCreditAmount(state) {
    const difference = state.shoppingCartTotals.OrderCostsTax.rawPrice + state.shoppingCartTotals.OrderCostsNet.rawPrice + state.shoppingCartTotals.ShippingCostsNet.rawPrice + state.shoppingCartTotals.ShippingCostsTax.rawPrice + state.shoppingCartTotals.OrderLinesTotalNet.rawPrice + state.shoppingCartTotals.OrderLinesTotalTax.rawPrice - state.creditAmount;
    return difference < 0 ? state.creditAmount + difference : state.creditAmount;
  },
  stockCheckProgress (state) {
    return state.stockCheckProgress;
  }
};

const mutations = {
  initConfig (state, config) {
    state.shoppingCartTotalsFormat = config.shoppingCartTotalsFormat;
    state.shoppingCartControlTotalsFormat = config.shoppingCartControlTotalsFormat;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setProducts (state, products) {
    const orderLines = [...state.orderLines];
    orderLines.forEach(orderLine => {
      orderLine.setProduct(products.filter(product => product.id === orderLine.productId)[0]);
      orderLine.setComputedQuantity();
    });
  },
  setOrderLines (state, orderLines) {
    state.orderLines = orderLines;
    state.totalOrderLines = orderLines.length;
  },
  setLoadingOrderLines (state, loading) {
    state.loadingOrderLines = loading;
  },
  setValidatingStock (state, validating) {
    state.validatingStock = validating;
  },
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  },
  setTotalAndCount (state, totalProducts) {
    state.totalProductsCount = totalProducts;
  },
  setShoppingCartEmpty (state, empty) {
    state.shoppingCartEmpty = empty;
  },
  setProductPrice (state, productWithPrice) {
    state.orderLines.forEach((orderLine) => {
      if (orderLine.productId === productWithPrice.id) {
        orderLine.product.prices = productWithPrice.prices;
        orderLine.setOrderLineTotal();
      }
    });
  },
  setProductStock (state, productWithStock) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === productWithStock.id) {
        orderLine.product.setStock(productWithStock.stock);
        orderLine.setComputedQuantity();
      }
    });
  },
  updateOrderLineQuantity (state, { lineId, quantity, unitCode, totalCount, showPrices }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].quantity = quantity;
    state.orderLines[index].unitCode = unitCode;

    // update total orderline quantities
    let prodCode = state.orderLines[index].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    if (showPrices) {
      state.orderLines[index].setOrderLineTotal();
    }
    state.orderLines[index].setComputedQuantity();
  },
  updateOrderLineComment (state, { lineId, comment }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].comments = comment;
  },
  deleteOrderLine (state, { orderLineIndex, totalCount }) {
    const orderLines = [ ...state.orderLines ];
    let prodCode = state.orderLines[orderLineIndex].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    orderLines.splice(orderLineIndex, 1);
    state.orderLines = orderLines;
  },
  setShoppingCartTotals (state, totals) {
    let totalsObj = {};
    totals.OrderTotal += state.donationAmount;
    totals.OrderLinesTotal += state.donationAmount;
    totals.OrderTotal -= state.creditAmount;
    totals.OrderLinesTotal -= state.creditAmount;
    if (totals.OrderTotal < 0) {
      totals.OrderTotal = 0
    }
    if (totals.OrderLinesTotal < 0) {
      totals.OrderLinesTotal = 0
    }

    Object.keys(totals).forEach(key => {
      totalsObj[key] = { price: formatPrice(totals[key]), rawPrice: totals[key] };
    });
    state.shoppingCartTotals = totalsObj;
    // BESPOKE PI-1094
    // Set minimum order quauntity to the bespoke webshop setting instead of the minimum order quantity on debtor level
    const minimumOrderQuantity = window.vue.globalData.webshopSettings.bespokeGlobalWs.minimumOrderAmount;
    state.minimumOrderQuantityPrice = formatPrice(minimumOrderQuantity);
    state.minimumOrderQuantityRawPrice = minimumOrderQuantity;
    // END BESPOKE PI-1094
    state.orderLinesTotalPrice = formatPrice(totals.OrderLinesTotal);
    state.orderLinesTotalRawPrice = totals.OrderLinesTotalNet;
  },
  setCouponCode (state, { CouponCode, HasCoupon }) {
    state.couponCode = CouponCode;
    state.hasCoupon = HasCoupon;
  },
  setShoppingCartInitizalized (state, intitialized) {
    state.shoppingCartInitizalized = intitialized;
  },
  checkStockState (state) {
    const orderLines = [...state.orderLines];
    let invalidLines = [];
    orderLines.forEach(orderLine => {
      if (orderLine.product.customBooleans.STOCK_PRODUCT) {
        if (orderLine.product.stock.stockTotal < orderLine.totalOrderLinesQuantity) {
          invalidLines.push(orderLine);
        }
      }
    });
    if (invalidLines.length) {
      state.stockValid = false;
      state.invalidOrderLines = invalidLines;
    } else {
      state.stockValid = true;
      state.invalidOrderLines = [];
    }
  },
  emptyShoppingCart (state) {
    state.orderLines = [];
    state.totalProductsCount = 0;
  },
  // BESPOKE
  setDonationAmount (state, donation) {
    state.donationAmount = donation;
  },
  setCreditAmount (state, credit) {
    state.creditAmount = credit;
  },
  setStockCheckProgress (state, progress) {
    state.stockCheckProgress = progress;
  }
}

const actions = {
  initShoppingCart ({ commit, dispatch }) {
    dispatch('getOrderLines');
    commit('setShoppingCartInitizalized', true);
    // BESPOKE PI
    dispatch('getInitialDonationAmount');
    dispatch('getInitialCreditAmount');
    window.$('#checkout-loader').remove();
    // END BESPOKE PI
  },
  initShoppingCartControl ({ dispatch }) {    
    dispatch('getOrderLinesShoppingCartControl');
    // BESPOKE PI
    dispatch('getInitialDonationAmount');
    dispatch('getInitialCreditAmount');
    // END BESPOKE PI
  },
  getShoppingCartTotals ({ commit, rootGetters, dispatch }) {
    axios.post(rootGetters.shoppingCartTotalsEndpoint, {})
      .then(res => {
        const data = res.data.d.Totals;
        commit('setShoppingCartTotals', data.Prices);
        dispatch('renderCheckoutButton');
        if (rootGetters.useCoupons) {
          commit('setCouponCode', data.CouponInfo);
        }
      });
  },
  getShoppingCartTotalAndCount ({ commit, rootGetters }) {
    axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          commit('setTotalAndCount', data.Totals.ProductCount);
        } else {
          // TODO ERROR HANDLING
        }
      });
  },
  getOrderLines ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation');
          } else {
            commit('setShoppingCartEmpty', true);
            dispatch('renderCheckoutButton');
          }
        } else if (data.Status === 'Failure') {
          dispatch('renderCheckoutButton');
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getOrderLinesShoppingCartControl ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation', false);
          } else {
            commit('setShoppingCartEmpty', true);
          }
        } else if (data.Status === 'Failure') {
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getProductInformation ({ getters, rootGetters, commit, dispatch }, checkStock = true) {
    axios.post(getters.productInformationEndpoint, getters.productCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        commit('setProducts', products);

        if (rootGetters.showStock && checkStock) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        }
      });
  },
  validateShoppingCartStock ({ commit, getters, rootGetters, dispatch }) {
    // BESPOKE PI-1222
    // Only do the stock check with loader if it is set in the web.config
    if (vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartCheckout) {
      commit('setValidatingStock', true);
      let counter = 0; let orderLinesLength = getters.orderLines.length;
      const products = getters.orderLines.map(orderLine => orderLine.product);
      products.forEach(product => {
        axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
          .then(res => {
            counter++;
            // BESPOKE PI
            // Set stock check percentage based on how many products stock have been checked
            if (vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartCheckout) {
              commit('setStockCheckProgress', Math.round(counter / orderLinesLength * 100));
            }
            // END BESPOKE PI
            product.setStock(res.data.d);
            commit('setProductStock', product);
            if (counter === orderLinesLength) {
  
              // BESPOKE PI-1222
              if (vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartCheckout) {
                commit('setStockCheckProgress', 0);
              }
              // END BESPOKE PI-1222
  
              commit('checkStockState');
              if (getters.invalidOrderLines.length) {
                const message = window.vue.translations.message.message_invalid_stock_orderlines;
                const offset = window.$('#stock-warning').offset().top - 75;
                window.updateErrorMessage(message);
                window.scrollTo({ top: offset, behavior: 'smooth' });
                // BESPOKE PI-1231
                window.$('#checkout-loader').remove();
                // END BESPOKE PI-1231
                dispatch('renderCheckoutButton');
              } else {
                // BESPOKE PI-1231
                window.$('#checkout-button-wrapper').append('<span id="checkout-loader" class="loader loader-pink"></span>');
                // END BESPOKE PI-1231
                dispatch('checkout');
              }
              commit('setValidatingStock', false);
            }
          });
      });
    } else {
      dispatch('checkout');
    }    
    // END BESPOKE PI-1222
  },
  getProductStock ({ rootGetters, getters, commit, dispatch }, products) {
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    // BESPOKE PI-1222
    // Set checking stock to true
    // 1. validateStockInitial is set from the Web.config, to check if the stock needs to be validated on the page loads
    // 2. skipStockValidationOnPageRefresh can be set from anywhere, which gives the option to cancel
    //    validateStockInitial if it is set to true in the sessionstorage. In this case 
    //    sessionStorage.setItem('skipStockCheckOnPageRefresh', 'true') has been implemented in uc_single_checkout
    //    since the page needs to reload after saving changes to the delivery address, which we dont want
    //    triggering a validateStockCheck
    var validateStockInitial = vue.globalData.webshopSettings.bespokeGlobalWs.stockCheckCartInitial;
    var skipStockValidationOnPageRefresh = sessionStorage.getItem('skipStockCheckOnPageRefresh') == 'true';

    if (validateStockInitial && !skipStockValidationOnPageRefresh) { 
      commit('setValidatingStock', true);
    }
    // END BESPOKE PI-1222
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;

          // BESPOKE PI-1222
          // Set stock check percentage based on how many products stock have been checked
          if (validateStockInitial && !skipStockValidationOnPageRefresh) {            
            commit('setStockCheckProgress', Math.round(counter / orderLinesLength * 100));
          }
          // END BESPOKE PI-1222

          // BESPOKE PI-1415
          // If the available stock is smaller than the minimum 
          // order quantity or saleUnit we set the stock to 0
          var stockResponse = res.data.d;
          if (product.customDecimals.MIN_ORDER_QUANTITY > stockResponse.stockTotal || product.saleUnit > stockResponse.stockTotal ) {
            stockResponse.stockTotal = 0;
          }
          // END BESPOKE PI-1415
          
          product.setStock(res.data.d);
          commit('setProductStock', product);
          
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
            // BESPOKE PI-1222
            // Set a loader with a percentage indicator
            if (validateStockInitial && !skipStockValidationOnPageRefresh) { 
              commit('setValidatingStock', false);
              commit('setStockCheckProgress', 0);
            }
            // Clears skipStockCheckOnPageRefresh which can be set from uc_single_checkout
            // as a flag to not do the validateStockCheck on page reload e.g. after saving the delivery address
            if (skipStockValidationOnPageRefresh) {
              sessionStorage.removeItem('skipStockCheckOnPageRefresh');
            }
            // END BESPOKE PI-1222

          }
        });
    });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          // BESPOKE PI
          product.setPricesBespoke(pricesObj);
          // END BESPOKE PI
          commit('setProductPrice', product);
        });
      });
  },
  updateOrderLineQuantity ({ rootGetters, commit, dispatch }, orderLine) {    
    // BESPOKE PI
    // We update the orderLine quantity in the state before sending the post request
    // to the backend so that any stock quantity checks and view updates happen immediately
    // and do not have to wait for a response from the updateOrderLine call
    commit('updateOrderLineQuantity', {
      lineId: orderLine.LineId,
      quantity: parseInt(orderLine.Quantity),
      unitCode: orderLine.UnitCode,
      totalCount: parseInt(orderLine.TrueQuantity),
      showPrices: rootGetters.showPrices
    });

    if (rootGetters.stockLimit) {
      commit('checkStockState');
    }
    dispatch('renderCheckoutButton');

    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            
            commit('setShoppingCartTotals', data.Totals.Prices);
            dispatch('getShoppingCartTotalAndCount');

            dispatch('renderCheckoutButton');

            resolve(true);
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
            dispatch('getOrderLines');
          }
        });
    });
  },  
  // END BESPOKE PI
  updateOrderLineComment ({ rootGetters, commit }, orderLine) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Commenst });
            resolve();
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
          }
        });
    });
  },
  deleteOrderLine ({ rootGetters, getters, commit, dispatch }, orderLineId) {
    const orderLineIndex = getters.orderLines.findIndex(orderLine => orderLine.lineId === orderLineId);
    const orderLine = getters.orderLines[orderLineIndex];
    const payload = {
      LineId: orderLine.lineId,
      ShoppingCartId: orderLine.shoppingCartId,
      ProductId: orderLine.productId,
      Comments: orderLine.comments,
      Quantity: orderLine.quantity,
      UnitCode: orderLine.unitCode,
      IsFree: orderLine.isFree
    }
    axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: payload }).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('deleteOrderLine', {
          orderLineIndex: orderLineIndex,
          totalCount: data.LineToBeDeleted.TotalCount
        });
        commit('setTotalAndCount', data.TotalsCount.ProductCount);
        commit('setShoppingCartTotals', data.Totals.Prices);
        if (rootGetters.stockLimit) {
          commit('checkStockState');
          dispatch('renderCheckoutButton');
        }
        if (getters.orderLines.length === 0) {
          commit('setShoppingCartEmpty', true);
        }
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.ErrorMessage);
      }
    });
  },
  applyCoupon ({ rootGetters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.addCouponEndpoint, { couponCode: payload })
        .then(res => {
          const response = res.data.d;
          const status = response.Status;
          if (status === 'Success') {
            commit('setOrderLines', []);
            dispatch('getOrderLines');
            commit('setCouponCode', { CouponCode: payload, HasCoupon: true });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (status === 'CouponUsed' || status === 'NotFound') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            reject(response.Message);
          }
        });
    });
  },
  removeCoupon ({ rootGetters, getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.removeCouponEndpoint, { couponCode: getters.couponCode })
        .then(res => {
          const response = res.data.d;
          if (response.Status === 'Success') {
            commit('setOrderLines', []);
            dispatch('getOrderLines');
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (response.Status === 'Failure') {
            window.updateErrorMessage(response.Message);
            reject();
          }
        });
    });
  },
  emptyShoppingCart ({ rootGetters, commit }) {
    axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('emptyShoppingCart');
        commit('setShoppingCartEmpty', true);
        window.updateOrderMessage(data.Message);
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.Message);
      }
    });
  },
  renderCheckoutButton ({ getters }) {
    if (!getters.showCheckoutButton) {
      window.$('.checkout-button').addClass('disabled');
    } else {
      window.$('.checkout-button').removeClass('disabled');
    }
  },
  hidePageElements () {
    window.$('.vuejs-hook').hide();
  },
  checkout () {
    window.$('.uc_multisafepay .inputbutton').click();
  },
  addToCart ({ rootGetters, dispatch }, payload) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];
    return new Promise((resolve, reject) => {
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              window.updateErrorMessage(response.errorMessages);
            }
          } else {
            resolve(true);
            dispatch('getShoppingCartTotalAndCount');

            // REFERS TO FUNCTION CALLS IN CUSTOM.JS
            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              window.updateOrderMessage(message);
            }
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  },
  // BESPOKE PI
  // Added donation action
  updateDonation({ rootGetters, getters, commit, dispatch }, { prodCode, amount }) {
    axios.post(rootGetters.bespokeEndpoints.donation, { donationProduct: prodCode, donationAmount: amount })
        .then(res => {
          const response = res.data.d;
          commit('setDonationAmount', response);
          dispatch('getShoppingCartTotals');
          if (amount === 0) {
            window.updateOrderMessage(window.vue.translations.label.label_donation_removed);           
          } else {
            window.updateOrderMessage(window.vue.translations.label.label_donation_updated);
          }
        });
  },
  // END BESPOKE PI
  getInitialDonationAmount({ rootGetters, getters, commit, dispatch }) {
    axios.post(rootGetters.bespokeEndpoints.shoppingCartDonations, { language: rootGetters.language })
      .then(res => {
        const response = res.data.d.DonationsTotal;
        commit("setDonationAmount", response)
        dispatch('getShoppingCartTotals');
      });
  },
  getInitialCreditAmount({ rootGetters, getters, commit, dispatch }) {
    axios.post(rootGetters.bespokeEndpoints.getCredit, { })
        .then(res => {
          const response = res.data.d;
          commit("setCreditAmount", response)
          dispatch('getShoppingCartTotals');
        });
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
