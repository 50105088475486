<template>
  <div class="cmp-coupons">

    <div class="form-field utlz-row">
      <div class="utlz-col-md-12">
        <h2 v-translation="{ type: 'title', code: 'title_coupon_code' }" class="header-small"></h2>
      </div>
      <div class="utlz-col-md-9 flex-row-nowrap">
        <input type="text" @keyup.enter="handleEnter()" :value="couponCode" ref="couponCode" class="utlz-col" />
        <a v-if="!couponCode.length" class="button square theme-primary" @click="applyCouponCode()">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a>
        <a v-else class="button square theme-primary" @click="removeCouponCode()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </a>
        <transition name="fade">
          <span v-if="loading" class="loader"></span>
        </transition>
      </div>
    </div>

    <div class="">
      <transition name="fade">
        <div class="alert alert-success" v-if="hasCoupon">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <p v-translation="{ type: 'label', code: 'label_coupon_valid' }"></p>
        </div>
      </transition>
      <transition name="fade">
        <div class="alert alert-danger" v-if="errorMessage">
          <i class="fa fa-times" aria-hidden="true"></i>
          <!-- BESPOKE PI-1336: Changed error message to translatable message -->
          <p v-translation="{code: 'label_coupon_invalid', type: 'label' }"></p>
          <!-- END BESPOKE PI-1336 -->
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data () {
    return {
      couponUsed: false,
      coupondValid: false,
      errorMessage: '',
      loading: false
    };
  },
  computed: {
    ...mapGetters('shoppingCart', ['hasCoupon', 'couponCode', 'couponValid'])
  },
  methods: {
    ...mapActions('shoppingCart', ['applyCoupon', 'removeCoupon']),
    handleEnter () {
      const value = this.$refs.couponCode.value;
      if (!value && this.hasCoupon) {
        this.removeCouponCode();
      } else if (value) {
        this.applyCouponCode();
      }
    },
    applyCouponCode () {
      this.loading = true;
      this.applyCoupon(this.$refs.couponCode.value)
        .then(
          res => {
            this.couponUsed = true;
            this.errorMessage = '';
            this.loading = false;
          }
        )
        .catch(err => {
          this.errorMessage = err;
          this.loading = false;
        });
      this.$refs.couponCode.blur();
    },
    removeCouponCode () {
      this.loading = true;
      this.removeCoupon(this.$refs.couponCode.value)
        .then(res => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
      this.$refs.couponCode.blur();
    }
  }
}
</script>

<style>

</style>
