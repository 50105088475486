import axios from 'axios';
import { getUrlParams, createUrlParams } from 'src/utilities/url';
import { Product } from 'src/models/product.model';

const state = {
  loadingProducts: true,
  noProductsFound: false,
  productCodes: [],
  products: [],
  showMobileFilters: false,
  totalProducts: 0,
  currentPage: 1,
  totalPages: 1,
  layoutType: '',
  blockSize: 4,
  rangeFrom: 0,
  pageSize: 16,
  queryString: '',
  aggregations: false,
  pricingQueryString: '',
  activeFilters: {},
  filters: [],
  pageSizes: [],
  sort: '',
  sortingType: 0,
  sortingOptions: [],
  customerListId: '',
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  }
};

function startsWith(str, search) {
  return str.substring(0, search.length) === search;
}

const getters = {
  pageTitle (state) {
    return state.pageTitle ? state.pageTitle : state.queryString;
  },
  layoutType (state) {
    return state.layoutType;
  },
  language (state, getters, rootState, rootGetters) {
    return rootGetters.language;
  },
  blockSize (state) {
    return state.blockSize;
  },
  totalProducts (state) {
    return state.totalProducts;
  },
  totalPages () {
    return Math.ceil(state.totalProducts / state.pageSize);
  },
  currentPage (state) {
    return state.currentPage;
  },
  loadingProducts (state) {
    return state.loadingProducts;
  },
  pageRange (state, getters) {
    let to;
    let pageFillCount = state.totalProducts % state.pageSize;
    if (pageFillCount && getters.totalPages === state.currentPage) {
      to = state.rangeFrom + pageFillCount;
    } else {
      to = state.pageSize + state.rangeFrom;
    }
    return {
      from: state.rangeFrom + 1,
      to: to
    };
  },
  showMobileFilters (state, getters, rootState, rootGetters) {
    if (rootGetters.screenWidth < 991) {
      return state.showMobileFilters;
    } else {
      return true;
    }
  },
  noProductsFound (state) {
    return state.noProductsFound;
  },
  searchConfig(state, getters, rootState, rootGetters) {
    let stringProperties = state.searchConfig.StringProperties;
    // Check if stringProperties is not null or undefined
    if (stringProperties) {
      // Check if any key starts with 'qqqqq|'
      if (Object.keys(stringProperties).some(key => startsWith(key, 'qqqqq|'))) {
        // If yes, create a new object with the modified keys
        stringProperties = Object.keys(stringProperties).reduce((acc, key) => {
          const value = stringProperties[key];
          // Check if the key starts with 'qqqqq|'
          if (startsWith(key, 'qqqqq|')) {
            // If it does, create a new key without 'qqqqq|'
            acc[key.substring(6)] = value;
          } else {
            // If it doesn't, keep the original key
            acc[key] = value;
          }
          return acc;
        }, {});
      } else {
        // If no key starts with 'qqqqq|', use state.activeFilters
        stringProperties = state.activeFilters;
      }
    } else {
      // If stringProperties is null or undefined, use an empty object
      stringProperties = state.activeFilters;
    }
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: stringProperties,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      MinSearchScore: rootGetters.minScore,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  filters (state) {
    return state.filters;
  },
  elasticProductsEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const from = state.rangeFrom;
    const language = rootGetters.language;
    const size = state.pageSize;
    const aggregations = state.aggregations;
    const pricingQueryString = state.pricingQueryString;
    const customerListId = state.customerListId;
    const query = !state.queryString ? '' : state.queryString;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    const groupProducts = rootGetters.groupProducts;
    const sort = state.sort;
    const sortingType = state.sortingType;
    var sortingstring = ''

    if (sort !== null) sortingstring = '&sort=' + sort + '|' + sortingType;

    return `${endpoint}?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=${groupProducts}`;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  customerListId (state) {
    return state.customerListId;
  },
  productCodes (state) {
    return state.productCodes;
  }
};

const mutations = {
  setLoadingStatus (state, payload) {
    state.loadingProducts = payload;
  },
  setSortingOptions (state, payload) {
    state.sortingOptions = payload;
  },
  setProducts (state, products) {
    state.products = products;
  },
  noProductsFound (state, productsFound) {
    state.noProductsFound = productsFound;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setFilters (state, aggregations) {
    const filters = [...aggregations];
    // Update new filters array with active status based on cached active filters array
    filters.forEach(filter => {
      // check for undefined as aggregations may give back new keys
      if (state.activeFilters[filter.id] !== undefined) {
        filter.value.forEach(filterValue => {
          if (state.activeFilters[filter.id].Values.indexOf(filterValue.key) > -1) {
            filterValue.active = true;
          } else {
            filterValue.active = false;
          }
        });
      }
    });
    state.filters = [...filters];
  },
  setProductStock (state, productWithStock) {
    let index = state.products.findIndex(product => product.id === productWithStock.id);
    state.products[index] = productWithStock;
  },
  setProductPrice (state, productWithPrice) {
    let index = state.products.findIndex(product => product.id === productWithPrice.id);
    state.products[index] = productWithPrice;
  },
  setSeoInformation (state, productWithSEO) {
    let index = state.products.findIndex(product => product.id === productWithSEO.id);
    state.products[index] = productWithSEO;
  },
  setProductImageBanners (state, productWithBanners) {
    let index = state.products.findIndex(product => product.id === productWithBanners.id);
    state.products[index] = productWithBanners;
  },
  setCustomerLists (state, productWithList) {
    let index = state.products.findIndex(product => product.id === productWithList.id);
    state.products[index] = productWithList;
  },
  setTotalProducts (state, totalProducts) {
    state.totalProducts = totalProducts;
  },
  setTotalPages (state) {
    state.totalPages = Math.ceil(state.totalProducts / state.pageSize);
  },
  initConfig (state, config) {
    state.rangeFrom = config.From;
    state.blockSize = config.BlockSize;
    state.aggregations = config.Aggregations;
    state.pricingQueryString = config.PricingQueryString;
    state.customerListId = config.CustomerListId;
    state.queryString = !config.QueryString ? '' : config.QueryString;
    state.pageSize = config.Size;
    state.pageTitle = config.CategoryName;
    state.pageSizes = config.PageSizes;
    state.layoutType = config.LayoutType;
    state.sortingOptions = config.SortingOptions;
    if (state.sortingOptions[0].Field !== '') {
      state.sort = state.sortingOptions[0].Field;
      state.sortingType = state.sortingOptions[0].Type;
    }
  },
  initSearchConfig (state, searchConfig) {
    state.searchConfig = { ...searchConfig };
  },
  updateConfigWithParameters (state, config) {
    state.rangeFrom = config.from;
    state.queryString = config.queryString;
    state.pageSize = config.pageSize;
    state.currentPage = config.currentPage;
  },
  changePage (state, pageNumber) {
    state.rangeFrom = (pageNumber - 1) * state.pageSize;
    state.currentPage = pageNumber;
    state.products = [];
  },
  changeLayout (state, layoutType) {
    state.layoutType = layoutType;
  },
  changePageSize (state, pageSize) {
    state.pageSize = pageSize;
  },
  changePageSorting (state, pageSorting) {
    state.sortingType = pageSorting.Type;
    state.sort = pageSorting.Field;
  },
  updateActiveFilters (state, { filterActive, filterId, filterValue, language }) {
    let activeFilters = { ...state.activeFilters };
    if (typeof activeFilters[filterId] === 'undefined') {
      activeFilters[filterId] = [];
    }
    if (filterActive) {
      activeFilters[filterId] = { Values: [filterValue], Language: language, PartialSearch: false };
    } else {
      delete activeFilters[filterId];
    }
    state.activeFilters = activeFilters;
  },
  setProductVariants (state, { products, productId }) {
    let index = state.products.findIndex(product => product.id === productId);
    state.products[index].setProductVariants(products);
  },
  toggleMobileFilters (state) {
    state.showMobileFilters = !state.showMobileFilters;
  },
  updateUrl (state) {
    let filters = {};
    if (Object.entries(state.activeFilters).length !== 0) {
      Object.keys(state.activeFilters).forEach(filter => {
        filters[filter] = state.activeFilters[filter];
      });
    }
    const urlParams = createUrlParams({
      searchtext: state.queryString,
      from: state.rangeFrom,
      pageSize: state.pageSize,
      filters: filters
    });
    history.pushState({}, '', urlParams);
  }
};

const actions = {
  initElastic ({ commit, dispatch }) {
    dispatch('readoutUrl');
    dispatch('getProducts');
  },
  readoutUrl ({ commit, rootGetters }) {
    if (window.location.search.length) {
      const params = getUrlParams(location.href);
      const from = parseInt(params.from) ? parseInt(params.from) : 0;
      const pageSize = parseInt(params.pageSize) ? parseInt(params.pageSize) : state.pageSize;
      const queryString = params.searchtext ? params.searchtext : state.queryString;
      const currentPage = from === 0 ? 1 : from / pageSize + 1;
      const filters = params.filters;

      commit('updateConfigWithParameters', { from: from, pageSize: pageSize, queryString: queryString, currentPage: currentPage });

      if (typeof filters !== 'undefined') {
        Object.keys(filters).forEach(filter => {
          commit('updateActiveFilters', {
            filterActive: true,
            filterId: filter,
            filterValue: filters[filter][0],
            language: rootGetters.language
          });
        });
      }
    }
  },
  getProducts ({ commit, getters, dispatch, rootGetters }) {
    commit('setLoadingStatus', true);
    if (getters.customerListId !== '') {
      const listEndpoint = `${rootGetters.productsFromList}?listId=${getters.customerListId}&debtorId=${rootGetters.customerId}`
      axios.post(listEndpoint)
        .then(res => {
          if (res.data) {
            commit('setTotalProducts', res.data.length);
            let pagerange = getters.pageRange;

            commit('setSortingOptions', []);
            commit('setProductCodes', res.data.slice(pagerange.from - 1, pagerange.to).map(x => {
              return { key: x, value: [x] };
            }));
            commit('noProductsFound', false);
            commit('setLoadingStatus', false);
            commit('setTotalPages');
            commit('updateUrl');
            dispatch('getProductInformation');
          } else {
            commit('noProductsFound', true);
          }
        });
    } else {
      axios.post(getters.elasticProductsEndpoint, getters.searchConfig)
        .then(res => {
          if (res.data.products) {
            commit('setProductCodes', res.data.products);
            commit('noProductsFound', false);

            // Filter sorting 1 is to sort on the description of the filter value
            if (rootGetters.filterSorting === 1) {
              res.data.stringAggregations.forEach(filter => {
                filter = filter.value.sort((a, b) => {
                  var keyA = a.key.toUpperCase();
                  var keyb = b.key.toUpperCase();

                  if (keyA < keyb) {
                    return -1;
                  }
                  if (keyA > keyb) {
                    return 1;
                  }
                  return 0;
                });
              });
            }
            commit('setFilters', res.data.stringAggregations);
            commit('setTotalProducts', res.data.totalItems);
            commit('setLoadingStatus', false);
            commit('setTotalPages');
            commit('updateUrl');
            dispatch('getProductInformation');
          } else {
            commit('noProductsFound', true);
          }
        })
        .catch(error => {
          commit('noProductsFound', true);
        });
    }
  },
  getProductInformation ({ commit, getters, dispatch, rootGetters }) {
    let mainProductCodes = getters.productCodes.map(code => code.key);
    axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        
        // BESPOKE PI-1221
        // Disabled getting stock for every elastic product, since we want to only load in the
        // stock on the product list item level, if it is manually requested
        // if (rootGetters.showStock) {
        //   dispatch('getProductStock', products);
        // } else {
        //   products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        // }
        // END BESPOKE PI-1221

        // BESPOKE PI
        // Disabled showPrices check since we need to push the product prices to the dataLayer even when not logged in
        //if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        //}
        // END BESPOKE PI

        if (rootGetters.showFavorites) {
          dispatch('getCustomerLists', products);
        }

        dispatch('getProductImageBanners', products);

        dispatch('getProductSeoInformation', products);

        commit('setProducts', products);
      });
  },
  getProductSeoInformation ({ commit, getters, dispatch, rootGetters }, products) {
    // BESPOKE
    let endpoint = rootGetters.bespokeEndpoints.seoInfo
    // let endpoint = rootGetters.seoInformationEndpoint + '/GetProductImageInformation';
    let productCodes = products.map(prod => { return prod.id });

    axios.post(endpoint, { productCodes: productCodes, language: rootGetters.language })
      .then(res => {
        res.data.d.forEach(SeoOject => {
          let product = products[products.findIndex(x => x.id === SeoOject.product)];
          product.setSeoInformation(SeoOject);
          commit('setSeoInformation', product);
        });
      });
      // END BESPOKE
  },
  getProductImageBanners ({ rootGetters, commit }, products) {
    let productCodes = products.map(prod => { return prod.id });
    axios.post(rootGetters.productImageBannersUrl, { productCodes: productCodes })
      .then(res => {
        res.data.d.forEach(productBanner => {
          let product = products[products.findIndex(x => x.id === productBanner.Key)];
          product.setProductImageBanners(productBanner.Value);
          commit('setProductImageBanners', product);
        });
      });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters, state }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode
    // BESPOKE PI
    // Added productsfetched to count if all products have been returned
    let productsFetched = 0;
    // END BESPOKE PI
    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          // BESPOKE PI
          // Set prices with bespoke method
          product.setPricesBespoke(pricesObj, product.saleUnit, product.customDecimals.MIN_ORDER_QUANTITY);           
          // END BESPOKE PI

          
          // Push products to datalayer once all product prices have been returned
           productsFetched++;
           if (productsFetched === state.pageSize) {
             dispatch('pushProductsViewedToDataLayer');
           }
          
          commit('setProductPrice', product);
        });
      });
  },
  getProductVariants ({ commit, dispatch, rootGetters, getters }, { variantCode, productId }) {
    const productVariantCodes = getters.productCodes.filter(code => code.key === variantCode)[0].value;
    axios.post(getters.productInformationEndpoint, productVariantCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        if (rootGetters.showStock) {
          products.forEach((product, index) => {
            axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
              .then(res => {
                product.setStock(res.data.d);
              });
          });
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }
        commit('setProductVariants', { products: products, productId: productId });
        dispatch('getProductVariantPrices', products);
      });
  },
  getProductVariantPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
        });
      });
  },
  getCustomerLists ({ commit, getters, rootGetters }, products) {
    const endpoint = `${rootGetters.getProductsInListsEndpoint}?debtorId=${rootGetters.customerId}`;
    axios.post(endpoint, getters.productCodes.map(code => code.key))
      .then(res => {
        Object.keys(res.data).forEach(prodCode => {
          let filteredProduct = products[products.findIndex(product => product.id === prodCode)];
          if (filteredProduct !== undefined) {
            filteredProduct.setCustomerLists(res.data[prodCode]);
            commit('setCustomerLists', filteredProduct);
          }
        });
      });
  },
  updateFilters ({ commit, dispatch, rootGetters }, changedFilter) {
    commit('updateActiveFilters', { ...changedFilter, language: rootGetters.language });
    commit('changePage', 1);
    dispatch('getProducts');
  },
  getProductStock ({ commit, rootGetters }, products) {
    // BESPOKE PI-1221
    // Return stock as a promise, so we may subscribe to it's 
    // asynchronous return values from other components 
    return new Promise((resolve, reject) => {
      products.forEach(product => {
        axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
          .then(res => {
            // BESPOKE PI-1415
            // If the available stock is smaller than the minimum 
            // order quantity or saleUnit we set the stock to 0
            var stockResponse = res.data.d;
            if (product.customDecimals.MIN_ORDER_QUANTITY > stockResponse.stockTotal || product.saleUnit > stockResponse.stockTotal ) {
              stockResponse.stockTotal = 0;
            }
            // END BESPOKE PI-1415
            product.setStock(stockResponse);
            commit('setProductStock', product);
            resolve(stockResponse.stockTotal);
          });
      });
    });   
    // END BESPOKE PI-1221 
  },
  changePage ({ commit, dispatch }, pageNumber) {
    commit('changePage', pageNumber);
    dispatch('getProducts');
  },
  changePageSize ({ commit, dispatch }, pageSize) {
    commit('changePageSize', pageSize);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changePageSorting ({ commit, dispatch }, pageSorting) {
    commit('changePageSorting', pageSorting);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changeLayout ({ commit }, layoutType) {
    commit('changeLayout', layoutType);
  },
  toggleMobileFilters ({ commit }) {
    commit('toggleMobileFilters');
  },
  pushProductsViewedToDataLayer({ state }) {
    const list = state.queryString ? 'search results' : 'product overview';
    const productsPayload = state.products.map((product, index) => {
      return {
        name: product.shortDescription,
        id: product.id,
        price: product.prices[0].rawPrice,
        brand: product.brand,
        category: state.pageTitle,
        variant: '',
        list: list,
        position: index
      }
    });

    window.dataLayer.push({
      'event': 'productImpression',
      'ecommerce': {
        'currencyCode': 'EUR',
        'impressions': productsPayload
      }
    });
  },
  pushProductClickToDataLayer ({ state }, productCode) {
    var position = 0;
    const product = state.products.filter((product, index) => {
      if (product.id === productCode) {
        position = index;
        return true;
      }
    })[0];
    const list = state.queryString ? 'search results' : 'product overview';

    window.dataLayer.push({
      'event': 'productClick',
      'ecommerce': {
        'currencyCode': state.currencyCode,
        'click': {
          'actionField': { 'list': list },
          'products': [{
            'name': product.shortDescription,
            'id': product.id,
            'price': product.prices[0].rawPrice,
            'brand': product.brand,
            'position': position
          }]
        }
      },
      'eventCallback': function () {
        document.location = product.targetUrl;
      }
    });
  },
  // BESPOKE PI
  // Added action to manually retrieve the stock for a singular product
  // since product stock is retrieved only on a user action in the product list items
  // getProductStockForProduct ({ state, commit, rootGetters }, products) {
  //   products.forEach(product => {
  //     axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
  //       .then(res => {
  //         product.setStock(res.data.d);
  //         commit('setProductStock', product);
  //       });
  //   });
  // },
  // END BESPOKE PI
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
