<template>
  <div class="cmp-product-stock-signal uc_product_stock">
    <template v-if="!useAsPrompt">
      <a @click="toggleNotificationModal()" class="link-signal btn-text">
      </a>
    </template>

    <div v-if="showNotificationModal || useAsPrompt" class="modal-default-large bg-fade-black">
      <div class="modal-dialog modal-lg">
        

        <!-- BESPOKE PI-1221: If we use stock notification as prompt, show a different content in the modal -->
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="useAsPrompt" >              
              <h1 v-translation="{ type: 'title', code: 'title_email_signal_prompt' }"></h1>
              <h2 v-translation="{ type: 'text', code: 'text_email_signal_subtitle' }"></h2>
            </template>
            <h1 v-else v-translation="{ type: 'title', code: 'title_email_signal_header' }"></h1>
          </div>
          <div class="modal-body">
            <div class="signal-text intro">
              <p v-translation="{ type: 'text', code: 'text_email_signal_stock'}"></p>
            </div>
            <div class="form-control-group">
              <input ref="emailAddress" type="text" class="input signal-email" v-model="emailAddress"/>
            </div>
            <div class="signal-text removal">
              <p v-translation="{ type: 'text', code: 'text_signal_stock_removed_after' }"></p>
            </div>
          </div>          
          <!-- END BESPOKE PI-1221 -->
          <div class="modal-footer">
            <a @click="sendEmailNotification()" v-translation="{ type: 'button', code: 'button_signal_confirm' }" class="pink-btn inputbutton inputbutton btn-u btn-u-sea-shop signal-confirm"></a>
            <a @click="toggleNotificationModal()" v-translation="{ type: 'button', code: 'button_signal_cancel' }" class="pink-btn inputbutton inputbutton btn-u btn-u-sea-shop signal-cancel"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  props: {
    productCode: { type: String, required: true },
    useAsPrompt: { type: Boolean, required: false, default: false },
    product: { type: Object, required: false }
  },
  data () {
    return {
      emailAddress: '',
      showNotificationModal: false
    }
  },
  computed: {
    ...mapGetters(['bespokeEndpoints'])
  },
  methods: {
    sendEmailNotification () {
      const payload = { signalEmail: this.emailAddress, productCode: this.productCode };
      axios.post(this.bespokeEndpoints.signalStockUrl, payload).then(
        res => {
          const response = res.data.d;
          if (response.errorMessage) {
            // refers to function call in custom.js
            window.updateErrorMessage(response.errorMessage);
          } else {
            // refers to function call in custom.js
            window.updateOrderMessage(response.confirmText);
            this.toggleNotificationModal();
          }
        }
      ).catch(err => {
        console.log(err);
      })
    },
    toggleNotificationModal () {
      this.showNotificationModal = !this.showNotificationModal;
      this.$emit('toggleNotificationModal');
    }
  }
}
</script>

<style>

</style>
