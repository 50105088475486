<template>
  <div v-if="!loading" class="donation-banners col-md-12">
    <div class="row">
      <div class="col-md-6" v-for="banner in donationBanners" :key="banner.ProdCode">
        <div class="banner-inner" :style="'background-color: ' + banner.BannerColor + ';'">
          <img class="bg-image" :src=" `/${banner.BackgroundImage}`" />
            <div class="don-text-container">
              <div class="text-top">
                <div v-html="banner.TextBoxUp" />
              </div>

              <div class="amounts-list">
                <div class="amt-row">
                  <span class="lbl" v-translation="{ code: 'lbl_donations', type: 'label'}"></span>
                  <span class="lbl" v-translation="{ code: 'lbl_adds_up', type: 'label'}"></span>
                </div>
                <div class="amt-row">
                  <span class="val">{{ banner.formattedtotalDonations }}</span>
                  <span class="val">{{ banner.formattedtotalDonationsPI }}</span>
                </div>
              </div>

              <div class="form-row">
                <a :href="banner.MoreInfoUrl" class="button white-btn-outline" v-translation="{ code: 'button_donation_info', type: 'button' }">
                </a>
                <img class="logo" :src="'/' + banner.LogoImg" alt="logo" />
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';

export default {
  data() {
    return {
      loading: true,
      donationBanners: []
    }
  },
  computed: {
    ...mapGetters(['language', 'bespokeEndpoints'])
  },
  created() {
    axios.post(this.bespokeEndpoints.activeDonations, { language: this.language }).then(donationBannersResponse => {
        this.donationBanners = donationBannersResponse.data.d;
        this.donationBanners.forEach(banner => {
          banner.formattedtotalDonations = formatPrice(banner.DonatedAmount);
          banner.formattedtotalDonationsPI = formatPrice(banner.DonatedAmount * 2);
        });
        this.loading = false;
    });    
  }
}
</script>

