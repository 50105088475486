<template>
  <!-- BESPOKE PI-1045: Added v-if render so we can forcefully render component after orderProduct
   event to reset quantity buttons to correct totals -->
  <div class="cmp-order-product normalize-headers" v-if="render" :class="parentClassRef">
    <!-- END BESPOKE PI-1045 -->   

    <utlz-order-normal
      v-if="orderType === 'normal'"
      @orderNormal="orderProductNormal($event)"
    ></utlz-order-normal>

    <!-- BESPOKE PI: Disabled saleUnit indicator -->
    <!-- <utlz-order-saleunit
      v-if="orderType === 'saleUnit'"
      :saleUnit="product.saleUnit"
    ></utlz-order-saleunit> -->
    <!-- END BESPOKE PI -->

    <utlz-order-units
      v-if="orderType === 'units' && product.stock"
      :units="product.units"
      :stockProduct="stockProduct"
      :stockTotal="product.stock.stockTotal"
      :selectedUnitCode="selectedUnitCode"
      @unitsChanged="unitsChanged($event)"
    ></utlz-order-units>

    <utlz-order-grouped v-if="orderType === 'grouped'" :product="product"></utlz-order-grouped>

    <utlz-order-volume-discount
      v-if="orderType === 'volumeDiscount'"
      :initialQuantity="initialQuantity"
      :volumeDiscount="product.prices"
      @volumeDiscountChanged="volumeDiscountChanged($event)">
    </utlz-order-volume-discount>

    <input v-if="useOrderComment" type="text" :value="orderCommentField" ref="orderComment" class="order-comment">

    <div class="order-product-wrapper flex-row-nowrap" :class="{ 'disabled': stockLimit && !isValidQuantity && !getStockManually }">
      <!-- BESPOKE PI: Added minimumOrderQuantity and computedInitialQuantity as property binding -->
      <utlz-quantity-buttons
        :initialQuantity="computedInitialQuantity"
        :allowZeroQuantity="allowZeroQuantity"
        :quantityFactor="quantityFactor"
        :minimumOrderQuantity="product.customDecimals.MIN_ORDER_QUANTITY"
        @quantityChanged="quantityChanged($event)">
      </utlz-quantity-buttons>
      <!-- END BESPOKE PI -->

      <!-- BESPOKE PI-1221: If getStockManually is true, we use a different method to order 
      the product so we can check if the stock is sufficient -->
      <template v-if="getStockManually">
        <a v-if="useOrderMode && !useTextOrderButton" @click="orderProductWithStockCheck(product)" class="button add-to-cart-btn small">
          <i class="uws-icon uws-cart" aria-hidden="true"></i>
        </a>
      </template>
      <template v-else>
        <a v-if="useOrderMode && !useTextOrderButton" @click="orderProduct" class="button add-to-cart-btn small">
          <i class="uws-icon uws-cart" aria-hidden="true"></i>
        </a>
      </template>
      <!-- END BESPOKE PI-1221 -->

      <a 
        v-translation="{ type: 'button', code: 'button_order' }"
        v-if="useOrderMode && useTextOrderButton" 
        @click="orderProduct" 
        class="order-button button large theme-primary">
      </a>

      <!-- Displays stocklimit exceeded tooltip -->
      <!-- BESPOKE PI-1221: Added v-if="!getStockManually", so we only use tooltip if stock is not  
      retrieved manually, e.g. through clicking hyperlink in the product list items -->
      <template v-if="!getStockManually">
        <transition name="fade">
          <template v-if="stockLimit && !isValidQuantity">
            <div class="utlz-tooltip stock-limit danger">
              <p v-translation="{ type: 'label', code: 'label_exceeds_stock_limit' }"></p>
            </div>
          </template>
        </transition>
      </template>
      <!-- END BESPOKE PI-1221 -->

    </div>

  </div>
</template>

<script>
import OrderNormal from 'src/components/webshop/order-product/blocks/OrderNormal.vue';
import OrderSaleUnit from 'src/components/webshop/order-product/blocks/OrderSaleUnit.vue';
import OrderUnits from 'src/components/webshop/order-product/blocks/OrderUnits.vue';
import OrderGrouped from 'src/components/webshop/order-product/blocks/OrderGrouped.vue';
import OrderVolumeDiscount from 'src/components/webshop/order-product/blocks/OrderVolumeDiscount.vue';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import { mapGetters, mapActions } from 'vuex';


export default {
  components: {
    'utlz-order-normal': OrderNormal,
    'utlz-order-saleunit': OrderSaleUnit,
    'utlz-order-units': OrderUnits,
    'utlz-order-grouped': OrderGrouped,
    'utlz-order-volume-discount': OrderVolumeDiscount,
    'utlz-quantity-buttons': QuantityButtons
  },
  props: {
    product: { type: Object, required: true },
    initialQuantity: { type: Number, required: false, default: 1 },
    initialUnitCode: { type: String, required: false },
    useOrderComment: { type: Boolean, required: false, default: false },
    orderComment: { type: String, required: false },
    useOrderMode: { type: Boolean, default: true },
    totalOrderLinesQuantity: { type: Number, required: false, default: null },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    useTextOrderButton: { type: Boolean, required: false, default: false },
    parentClassRef: { type: String, default: '', required: false },
    parentComponentName: { type: String, default: '', required: false },
    // BESPOKE PI
    // Added prop to check if product stock should be retrieved by manually clicking the get stock link
    getStockManually: { type: Boolean, required: false, default: false }
    // END BESPOKE PI
  },
  data () {
    return {
      quantity: this.initialQuantity,
      quantityFactor: 1,
      orderType: 'normal',
      selectedUnitCode: '',
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      // BESPOKE PI-1045
      // Added computedInitialQuantity
      computedInitialQuantity: 1,
      render: true
      // END BESPOKE PI-1045
    };
  },
  computed: {
    ...mapGetters(['stockLimit', 'showStock']),
    orderCommentField () {
      return this.orderComment;
    },
    isValidQuantity () {
      if (this.stockLimit && this.stockProduct && this.product.stock.stockTotal !== null) {
        if (this.useOrderMode || this.totalOrderLinesQuantity === null) {
          return this.quantity <= this.product.stock.stockTotal;
        } else {
          return this.totalOrderLinesQuantity <= this.product.stock.stockTotal;
        }        
      } else {
        return true;
      }
    }
  },
  watch: {
    product () {
      this.setOrderType();
      this.setOrderQuantities();
    }
  },
  methods: {
    setOrderType () {
      let type = 'normal';
      if (this.product.units) {
        type = 'units';
      } else if (this.product.saleUnit > 1) {
        type = 'saleUnit';
      }
      // BESPOKE PI-1045
      // Overwrite default orderType with volume discount if applicable
      // If loaded into the shoppingcart however, we do not display staffels
      // but we switch to using the saleunit for the factor multiplication
      let volumeDiscount = this.product.prices.length > 1 && !this.product.prices[0].isSalesAction;
      if (volumeDiscount && this.parentComponentName !== 'shoppingcart') {
        type = 'volumeDiscount';
      } else if (volumeDiscount && this.parentComponentName === 'shoppingcart') {
        type = 'saleUnit';
      }
      
      // END BESPOKE PI-1045
      this.orderType = type;
    },
    setOrderQuantities () {
      switch (this.orderType) {
        case 'normal':
          this.quantityFactor = 1;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {
            this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          }        
          break;
        case 'saleUnit':
          const initialQuantity = this.initialQuantity * this.product.saleUnit;
          this.quantityFactor = this.product.saleUnit;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {   
            this.quantity = initialQuantity > this.quantityFactor ? initialQuantity : this.quantityFactor;
          }
          break;
        case 'volumeDiscount':
          // BESPOKE PI-1045
          this.quantityFactor = this.product.prices[0].quantity < this.initialQuantity ? this.initialQuantity : this.product.prices[0].quantity;
          // END BESPOKE PI-1045
          this.quantity = this.initialQuantity > this.quantityFactor || this.allowZeroQuantity ? 0 : this.quantityFactor;
          break;
        case 'units':
          let index = 0;
          if (this.initialUnitCode) {
            this.product.units.filter((unit, i) => {
              if (unit.code === this.initialUnitCode) {
                index = i;
              }
            });
          }
          this.selectedUnitCode = this.product.units[index].code;
          this.quantityFactor = this.product.units[index].quantity;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {
            this.quantity = this.initialQuantity > 1 ? this.initialQuantity * this.quantityFactor : this.quantityFactor;
          }
          break;
      }
    },
    orderProduct () {
      let payload = this.getOrderPayload();
      this.addToCart(payload)
        .then(res => {
          this.quantity = this.quantityFactor;
          // BESPOKE PI-1045
          // Added rerender on next tick to reset computed property bindings
          // so that the quantities in QuantityButtons are reset
          this.render = false;          
          this.$emit('orderButtonClick')
          this.$nextTick(() => {
            this.render = true;            
            this.setOrderQuantities();
            this.setcomputedInitialQuantity();
          });
          // END BESPOKE PI-1045
        });
    },
    getOrderPayload () {
      return {
        prod_code: this.product.id,
        ord_qty: this.getComputedQuantity(this.quantity),
        unit_code: this.selectedUnitCode,
        prod_comment: this.useOrderComment ? this.$refs.orderComment.value : ''
      }
    },
    getComputedQuantity (quantity) {
      let computedQuantity = quantity;
      if (this.orderType === 'units') {
        computedQuantity = this.quantity / this.quantityFactor;
      } else if (this.orderType === 'volumeDiscount' && this.product.saleUnit > 1) {
        computedQuantity = this.quantity / this.product.saleUnit;
      } else if (this.orderType === 'saleUnit') {
        computedQuantity = this.quantity / this.product.saleUnit;
      }
      return computedQuantity;
    },
    quantityChanged ({ quantity }) {
      this.quantity = quantity;
      if (!this.useOrderMode) {
        this.$emit('quantityChanged', {
          id: this.product.id,
          unitCode: this.selectedUnitCode,
          computedQuantity: this.getComputedQuantity(this.quantity),
          // BESPOKE PI
          // Added true total quantity e.g. quantity x saleUnit
          trueQuantity: this.quantity
          // END BESPOKE PI
        });
      }
    },
    unitsChanged ({ quantity, unitCode }) {
      this.selectedUnitCode = unitCode;
      this.quantity = this.allowZeroQuantity ? 0 : quantity;
      this.quantityFactor = quantity;
      if (!this.useOrderMode) {
        this.$emit('unitsChanged', {
          unitCode: unitCode,
          quantity: quantity,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      }
    },
    volumeDiscountChanged (quantity) {
      this.quantity = quantity;
      this.quantityFactor = quantity;      

      // BESPOKE PI-1045
      // If zero quantity is enforced, reset quantity back to 0 on staffel change
      this.computedInitialQuantity = this.product.customDecimals.MIN_ORDER_QUANTITY;
      if (this.quantity > this.computedInitialQuantity) {        
        this.computedInitialQuantity = this.quantity;
      }      

      // Emit quantityChange event when staffel changes so that matrix
      // can update the matrix line quantities accordingly and update order button state
      this.$emit('quantityChanged', {
          id: this.product.id,
          unitCode: this.selectedUnitCode,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      // END BESPOKE PI-1045
    },
    setcomputedInitialQuantity () {
      if (this.allowZeroQuantity) {
        this.computedInitialQuantity = 0;
        return false;
      }

      if (this.parentComponentName === 'shoppingcart') {
        this.computedInitialQuantity = this.quantity;
        return false;
      }

      // Let  minimum order quantity take precedent over saleUnit 
      let saleUnit = this.product.saleUnit;
      let minimumOrderQuantity = this.product.customDecimals.MIN_ORDER_QUANTITY;
      if (saleUnit > 1) {
        this.computedInitialQuantity = this.product.saleUnit > minimumOrderQuantity ? this.product.saleUnit : minimumOrderQuantity;
      } 
      // If no applicable saleUnit on the product, let minimum order quantity take precedent 
      // over the first quantity in the prices object if it's actually higher than the first quantity
      else if (this.product.prices[0].quantity > minimumOrderQuantity) {
        this.computedInitialQuantity = this.product.prices[0].quantity;
      } else {
        this.computedInitialQuantity = minimumOrderQuantity;
        this.quantity = minimumOrderQuantity;
      }
    },
    ...mapActions('shoppingCart', ['addToCart']),
    ...mapActions('elastic', ['getProductStock']),
    // BESPOKE PI-1221
    orderProductWithStockCheck(product) {
      let quantity = this.quantity;
      this.getProductStock([product]).then(stockTotal => {
        if (stockTotal >= quantity) {
          this.orderProduct();
        } else if (stockTotal <= 0) {
          this.$emit('handleStockNotificationModal');
        } else if (stockTotal < quantity) {          
          this.$emit('handleOrderProductModal', { stockTotal: stockTotal });
        }
      });
    }
    // END BESPOKE PI-1221
  },
  created () {
    this.setOrderType();
    this.setOrderQuantities();

    // BESPOKE PI-1045
    this.setcomputedInitialQuantity();
    // END BESPOKE PI-1045 

    
  }
};
</script>

<style>
</style>
