<template>
  <div class="cmp-quantity-btns">
    <div class="quantity-btns-wrapper flex-row-nowrap align-center">
      <a class="quantity-btn button square small decrement fa fa-minus" @click="decrement"></a>
      <input class="quantity-fld" type="number" min="0" v-model="quantity" @change="manualQuantity">
      <a class="quantity-btn button square small increment fa fa-plus" @click="increment"></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialQuantity: { type: Number, required: false, default: 1 },
    quantityFactor: { type: Number, required: false, default: 1 },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    // BESPOKE PI-1045
    // Added minumum order quantity
    minimumOrderQuantity: { type: Number, required: true }
    // END BESPOKE PI-1045
  },
  data () {
    return {
      quantity: this.initialQuantity
    }
  },
  watch: {
    initialQuantity () {
      this.quantity = this.initialQuantity;
    }
  },
  methods: {
    quantityChanged () {
      this.quantity = parseInt(this.quantity);
      this.$emit('quantityChanged', { quantity: this.quantity });
    },
    decrement () {

      // BESPOKE PI-1045
      if (this.minimumOrderQuantity > 1 && this.quantity <= this.minimumOrderQuantity) {
        if (this.allowZeroQuantity) {
          this.quantity = 0;
          this.quantityChanged();
          return false;
        }
        // quantity factor may change due to staffel change in the dropdown elements, so check that 
        // miniumum order quantity is higher than the newly selected staffel
        if (this.minimumOrderQuantity > this.quantityFactor) {
          this.quantity = this.minimumOrderQuantity;        
          this.quantityChanged();
          return false;
        }         
      }
      // END BESPOKE PI-1045

      if (this.quantity <= this.quantityFactor) {
        if (this.allowZeroQuantity) {
          this.quantity = 0;
          this.quantityChanged();
        } else {
          this.quantity = this.quantityFactor;
        }
      } else {
        this.quantity -= this.quantityFactor;
        this.quantityChanged();
      }
    },
    increment () {
      // BESPOKE PI
      // Take minimum order quantity into account if zero quantity is allowed
      if (this.allowZeroQuantity) {
        if (this.quantity < this.minimumOrderQuantity) {
          this.quantity = this.minimumOrderQuantity;
        } else {
          this.quantity += this.quantityFactor; 
        }
      } else {
        this.quantity += this.quantityFactor; 
      }
      this.quantityChanged();
      // END BESPOKE
    },
    manualQuantity () {
      if (Math.sign(this.quantity) <= 0 || this.quantity === '') {
        this.quantity = parseInt(this.quantityFactor);
      } else if (this.quantity % this.quantityFactor !== 0) {
        this.quantity = parseInt(Math.ceil(this.quantity / this.quantityFactor) * this.quantityFactor);
      }
      // BESPOKE PI
      // Take minimum order quantity into account
      if (this.minimumOrderQuantity > 1 && this.quantity <= this.minimumOrderQuantity) {
        this.quantity = parseInt(this.minimumOrderQuantity);        
        this.quantityChanged();
        return false;
      }

      this.quantityChanged();
    }
  }
}
</script>

<style>

</style>
